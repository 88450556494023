
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { useEffect, useState } from "react";
import CampaignList from "../../components/campaign/CampaignList/CampaignList";
import UpcomingInterviewList from "../../components/interviews/UpcomingInterviewList/UpcomingInterviewList";
// import CandidateList from "../../components/candidates/CandidateList/CandidateList";
import { NewMatchesList } from "../../components/newMatches/NewMatchesList/NewMatchesList";
import StatsSidebar from "../../components/stats/StatsSidebar/StatsSidebar";
import HorizontalGraph from "../../components/stats/HorizontalGraph/HorizontalGraph";
import { newMatchesVariants, sidebarVariants } from "../../utils/animation";
import { centralContentVariants } from "./../../utils/animation";
import { selectPreviewCandidate, selectShowNewMatches } from "./../../store/selectors/Candidate";
import SearchFilterBar from "../../components/candidates/SearchFilterBar/SearchFilterBar";
import NewMatchBar from "../../components/candidates/NewMatchBar/NewMatchBar";
import Scrollbars from "react-custom-scrollbars-2";
import CandidatePreviewCard from "../../components/candidates/CandidatePreviewCard/CandidatePreviewCard";
import { selectCandidateFilterFavorites, selectCandidateFilterProfileStatus, selectCandidateSearchText } from "../../store/selectors/CandidateFilter";
import { useGetNewMatchCandidatesQuery } from "../../queries/NewMatches";
import { useGetCandidatesQuery, useToggleCandidateFavoriteStatusQuery } from "../../queries/Candidate";
import { selectSelectedCampaign } from "../../store/selectors/Campaign";
import { clearWhiteSpacesAndConvertToLowerCase } from "../../utils/misc";
import { ScrollThumbVertical, ScrollTrackVertical } from "../../components/shared/CustomScrollBar/CustomScrollBar";
import { setPreviewCandidate } from "../../store/slices/Candidate";
// import CandidateListItem from "../../components/candidates/CandidateListItem/CandidateListItem";
import EmptyCandidateListItem from "../../components/candidates/EmptyCandidateListItem/EmptyCandidateListItem";
import { useLocation } from "react-router-dom";

const CandidateDetails = () => {
    const selectedCampaign = useSelector(selectSelectedCampaign);
    const isFavorites = useSelector(selectCandidateFilterFavorites);
    const profileStatus = useSelector(selectCandidateFilterProfileStatus);
    const searchText = useSelector(selectCandidateSearchText);
    const previewCandidate = useSelector(selectPreviewCandidate);
    const [previewExited, setPreviewExited] = useState(true);
    const dispatch = useDispatch();
    const candidateFilterParams = {
        campaign_id: selectedCampaign?.campaign_id || null,
        profile_status: profileStatus,
        is_favourite: isFavorites,
    };
    const showNewMatches = useSelector(selectShowNewMatches);
    const { data: newMatches } = useGetNewMatchCandidatesQuery();
    const { isLoading, data } = useGetCandidatesQuery(candidateFilterParams);
    const [localData, setLocalData] = useState(data);

    const { mutate: toggleFavoriteStatus } =
        useToggleCandidateFavoriteStatusQuery(candidateFilterParams);

    const selectCandidate = (candidate) => dispatch(setPreviewCandidate(candidate));

    const onFavorite = (event, candidate) => {
        event.stopPropagation();
        toggleFavoriteStatus(candidate);
    };
    console.log(previewExited);
    const location = useLocation();
    const route_data = location.search; 
    let candidate_id = route_data.split('&')[0].split('=')[1] 
    console.log('candidate_id', candidate_id);
    console.log('route_data', route_data); 
 
    const renderCandidateList = (data) =>
        !!data?.length ? (
            data.map((candidate) => {
                //check all candidate list and show candidate match id only
                if((candidate.candidate_id).toString() === candidate_id){ 
                    return (
                        <CandidatePreviewCard
                        key={candidate.candidate_profile_status_id}
                        data={candidate}
                        onClose={() => selectCandidate(null)}
                        onFavorite={(event) => onFavorite(event, candidate)}
                        />
                    )
                } else {
                    return null
                }
            })
        ) : !isLoading ? (
            <EmptyCandidateListItem />
        ) : null;

    useEffect(() => {
        setLocalData(
            data?.filter((candidate) => {
                const search = clearWhiteSpacesAndConvertToLowerCase(searchText);
                const firstName = clearWhiteSpacesAndConvertToLowerCase(candidate?.first_name);
                const lastName = clearWhiteSpacesAndConvertToLowerCase(candidate?.last_name);
                const techStack = clearWhiteSpacesAndConvertToLowerCase(candidate?.tech_stack);
                const state = clearWhiteSpacesAndConvertToLowerCase(candidate?.state);
                const city = clearWhiteSpacesAndConvertToLowerCase(candidate?.city);
                const country = clearWhiteSpacesAndConvertToLowerCase(candidate?.country);

                return (
                    (firstName + lastName).includes(search) ||
                    (lastName + firstName).includes(search) ||
                    techStack.includes(search) ||
                    state.includes(search) ||
                    city.includes(search) ||
                    country.includes(search)
                );
            })
        ); 
    }, [searchText, data, setLocalData]);
 
    return (
        <div className="home d-flex">
            <AnimatePresence initial={false} exitBeforeEnter>
                {!showNewMatches && (
                    <motion.div
                        variants={sidebarVariants}
                        initial={"hidden"}
                        animate={"show"}
                        exit={"hidden"}
                        className="home__left-sidebar d-flex flex-column"
                    >
                        <CampaignList />
                        <UpcomingInterviewList />
                    </motion.div>
                )}
            </AnimatePresence>
            <motion.div className="home__content-wrapper">
                <motion.div className="home__content-container">
                    <AnimatePresence initial={false} exitBeforeEnter>
                        {!showNewMatches ? (
                            <motion.div
                                key="candidates"
                                variants={centralContentVariants}
                                initial={"hidden"}
                                animate={"show"}
                                exit={"hidden"}
                                className="home__content d-flex flex-column flex-grow-1"
                            >
                                {/* <CandidateList /> */}
                                <SearchFilterBar total={!!searchText ? localData?.length : data?.length} />
                                <NewMatchBar data={newMatches} />
                                <Scrollbars
                                    id="candidateList"
                                    className="candidate-list"
                                    renderThumbVertical={ScrollThumbVertical}
                                    renderTrackVertical={ScrollTrackVertical}
                                    renderThumbHorizontal={() => <div />}
                                    renderTrackHorizontal={() => <div />}
                                >
                                    <AnimateSharedLayout type="crossfade">
                                        <motion.div
                                            id="candidateGrid"
                                            className={
                                                "candidate-list__grid" 
                                            }
                                        >
                                            {renderCandidateList(!!searchText ? localData : data)}
                                        </motion.div>
                                        <AnimatePresence onExitComplete={() => setPreviewExited(true)}>
                                            {previewCandidate && (
                                                <CandidatePreviewCard
                                                    key={previewCandidate?.candidate_profile_status_id}
                                                    data={previewCandidate}
                                                    onClose={() => selectCandidate(null)}
                                                    onFavorite={(event) => onFavorite(event, previewCandidate)}
                                                />
                                            )}
                                        </AnimatePresence>
                                    </AnimateSharedLayout>
                                </Scrollbars>
                            </motion.div>
                        ) : (
                            <motion.div
                                key="new-matches"
                                variants={newMatchesVariants}
                                initial={"hidden"}
                                animate={"show"}
                                exit={"hidden"}
                                className="new-match"
                            >
                                <NewMatchesList />
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <AnimatePresence initial={false} exitBeforeEnter>
                        {!showNewMatches && (
                            <motion.div
                                variants={sidebarVariants}
                                initial={"hidden"}
                                animate={"show"}
                                exit={"hidden"}
                                className="home__right-sidebar"
                            >
                                <StatsSidebar />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </motion.div>
                <HorizontalGraph containerClassName="home__graph-container" />
            </motion.div>
        </div>
    );
};

export default CandidateDetails