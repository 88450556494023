import PropTypes from "prop-types";
import "./CampaignsStatsCardListItem.css";

const CampaignsStatsCardListItem = ({ backgroundColorVar, title, figure = "N/A" }) => {
  const figureBoxStyle = {
    backgroundColor: `var(${backgroundColorVar})`,
  };

  return (
    <div className="campaigns-stats-card__container">
      <div style={figureBoxStyle} className="campaigns-stats-card__figure-box">
        <span className="campaigns-stats-card__figure">
          {+figure < 10 && +figure >= 0 ? `0${figure}` : figure}
        </span>
      </div>
      <span className="campaigns-stats-card__title">{title}</span>
    </div>
  );
};

CampaignsStatsCardListItem.propTypes = {
  backgroundColorVar: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  figure: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CampaignsStatsCardListItem;
