import { connect } from "formik";
import "./StandardInputField.css";

const StandardInputField = ({ field, form: { errors, touched }, label, formik, ...meta }) => (
  <div className="standard-input-field">
    {!!label && <span className="label">{label}</span>}
    <input
      className={`state-transition${
        touched[field.name] && !!errors[field.name]
          ? " invalid"
          : touched[field.name] && errors[field.name] === undefined
          ? " valid"
          : ""
      }`}
      {...field}
      {...meta}
    />
  </div>
);

export default connect(StandardInputField);
