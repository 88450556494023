import "./HorizontalGraphMobile.css";
import PropTypes from "prop-types";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import HorizontalGraphTooltip from "./HorizontalGraphTooltip/HorizontalGraphTooltip";
import { useGetStatsQuery } from "../../../queries/Stats";

const HorizontalGraphMobile = ({ containerClassName }) => {
  const { isSuccess, data } = useGetStatsQuery();

  return (
    <div
      className={"horizontal-graph-mobile" + (!!containerClassName ? ` ${containerClassName}` : "")}
    >
      <ResponsiveContainer>
        <LineChart
          data={data?.client_graph_data}
          margin={{ top: 0, right: 0, bottom: 0, left: -25 }}
        >
          {isSuccess && (
            <>
              <CartesianGrid
                verticalFill={["#b2c8d83d", "#b2c8d86e"]}
                stroke="#295F83"
                strokeLinecap="round"
                strokeWidth={0}
              />
              <XAxis
                dataKey="month"
                axisLine={false}
                tickLine={false}
                orientation="top"
                stroke="#134067"
                fontSize={12}
                fontWeight={700}
                tickMargin={8}
                allowDecimals={false}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                stroke="#134067"
                fontSize={12}
                fontWeight={700}
                tickCount={4}
                type="number"
                domain={[0, "dataMax"]}
                padding={{ top: 10, bottom: 0 }}
                allowDecimals={false}
              />
              <Tooltip content={<HorizontalGraphTooltip />} />
              <Line
                type="linear"
                dataKey="voted_up"
                stroke="#509ec6"
                activeDot={{ stroke: "transparent" }}
                strokeWidth={2}
                strokeDasharray="2 2"
                dot={false}
              />
              <Line
                type="linear"
                dataKey="voted_down"
                stroke="#FF7BAC"
                activeDot={{ stroke: "transparent" }}
                strokeWidth={2}
                strokeDasharray="4 6"
                dot={false}
              />
              <Line
                type="linear"
                dataKey="interviewed"
                stroke="#5B7E95"
                activeDot={{ stroke: "transparent" }}
                strokeWidth={2}
                strokeDasharray="2 6"
                dot={false}
              />
              <Line
                type="linear"
                dataKey="hired"
                stroke="#21D4D2"
                activeDot={{ stroke: "transparent" }}
                strokeWidth={2}
                dot={false}
              />
            </>
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

HorizontalGraphMobile.propTypes = {
  containerClassName: PropTypes.string,
};

export default HorizontalGraphMobile;
