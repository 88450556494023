import axios from "axios";
import config from "../config";
import { clearStorage, getItem } from "./localStorage";
import { incrementWaitingResponseCount, decrementWaitingResponseCount } from "../store/slices/App";
import { store } from "../store";
import { navigate } from "./history";
import { routes } from "../router/routes";
import { decryptFunc, encryptFunc } from "./encryption";
import { logoutSuccess } from "../store/slices/Auth";
import queryStore from "../queries";

export const attachQueryParams = (endpoint, params) => {
  if (params !== undefined) {
    let queryString = Object.keys(params)
      .reduce((a, k) => {
        if (params[k] !== null && params[k] !== undefined) {
          a.push(k + "=" + encodeURIComponent(params[k]));
        }
        return a;
      }, [])
      .join("&");

    return !!queryString ? `${endpoint}?${queryString}` : endpoint;
  }

  return endpoint;
};

const api = axios.create({
  baseURL: config.api.baseUrl,
});

api.interceptors.request.use(
  (config) => {
    const token = getItem("token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    /* 'upload/file' endpoint can't be encrypted
     * formData boundary can't be calculated when payload is encrypted
     */
    if (!!config.data && !config.url.includes("upload/file")) {
      config.data = { data: { newData: encryptFunc(config.data) } };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    // 'upload/file' endpoint's response is not encrypted
    if (!response?.request?.responseURL.includes("upload/file")) {
      for (const key in response.data) {
        return decryptFunc(response.data);
      }
    }

    return response?.data;
  },
  async function (error) {
    if (
      error?.response?.status === 401 ||
      error?.response?.data?.message === "jwt expired" ||
      error?.response?.data?.message === "invalid token"
    ) {
      clearStorage();
      store.dispatch(logoutSuccess());
      navigate(routes.login);
      queryStore.removeQueries();

      return Promise.reject({ ...error, errorId: 401 });
    }

    // return Error object with Promise
    return Promise.reject(error);
  }
);

export default async function request({ url, method, data, headers = {} }) {
  try {
    store.dispatch(incrementWaitingResponseCount());
    const { data: responseData } = await api({ method, url, headers, data });

    return responseData;
  } catch (error) {
    let errorMessage = "";

    if (!navigator.onLine) {
      errorMessage = "Check you internet connection";
    } else if (error?.response === undefined) {
      errorMessage = "Server is not responding";
    } else if (error?.response?.status >= 500) {
      errorMessage = "Server Error. Please, reload the app";
    }

    if (!!errorMessage) {
      throw new Error(errorMessage);
    } else {
      throw error;
    }
  } finally {
    store.dispatch(decrementWaitingResponseCount());
  }
}
