import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedCampaign: null,
  isCampaignRequestedModalVisible: false,
};

const CampaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setSelectedCampaign(state, { payload }) {
      state.selectedCampaign =
        state.selectedCampaign?.campaign_id === payload?.campaign_id
          ? initialState.selectedCampaign
          : payload;
    },
    clearSelectedCampaign(state) {
      state.selectedCampaign = initialState.selectedCampaign;
    },
    setCampaignRequestedModalVisibility(state, { payload }) {
      state.isCampaignRequestedModalVisible = payload;
    },
  },
});

export const { setSelectedCampaign, clearSelectedCampaign, setCampaignRequestedModalVisibility } =
  CampaignSlice.actions;
export default CampaignSlice.reducer;
