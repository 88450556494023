export const ScrollThumbVertical = ({ color = "#B2C8D8" }) => (
  <div
    style={{
      backgroundColor: color,
      position: "relative",
      display: "block",
      width: " 100%",
      cursor: "pointer",
      borderRadius: "inherit",
    }}
  />
);

export const ScrollTrackVertical = ({
  color = "#DAE8F2",
  width = "4px",
  borderRadius = "3px",
  top = "4px",
  right = "5px",
  bottom = "4px",
}) => (
  <div
    style={{
      backgroundColor: color,
      position: "absolute",
      borderRadius,
      width,
      top,
      right,
      bottom,
    }}
  />
);
