import * as Yup from "yup";
import { regex } from "../utils/regex";

export const accountSettingsValidationRules = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  phone_number: Yup.string().required("Phone is required"),
  email_id: Yup.string().required("Email is required").email("Invalid email format"),
  job_title: Yup.string().required("Job title is required"),
  company_name: Yup.string().required("Company name is required"),
  company_url: Yup.string().required("Company URL is required").matches(regex.url, {
    message: "Invalid format of Company URL",
  }),
});

export const changePasswordValidationRules = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password should be eight (8) characters in length")
    .matches(regex.password, "Password doesn't follow the second requirement described below"),
  repeatPassword: Yup.string()
    .required("Repeat Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const twoStepVerificationValidationRules = Yup.object().shape({
  code: Yup.string()
    .required("Code is required")
    .matches(regex.otp, "Code must contain digits only")
    .min(4, "Code must contain 4 digits")
    .max(4, "Code must contain 4 digits"),
});
