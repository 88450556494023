import "./CustomOption.css";
import PropTypes from "prop-types";
import React from "react";

const CustomOption = ({ id, title, value, onChange, checked = false, type = "checkbox" }) => {
  return (
    <label htmlFor={id} key={title} className="pointer option no-tap-highlight">
      <input
        id={id}
        type={type}
        checked={checked}
        disabled={!checked}
        onChange={onChange}
        className="pointer state-transition"
      />
      <input type="text" value={value} placeholder={title} autoComplete="off" onChange={onChange} />
    </label>
  );
};

CustomOption.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  type: PropTypes.oneOf(["checkbox", "radio"]),
};

export default React.memo(CustomOption);
