import "./Navigation.css";
import { useLocation } from "react-router-dom";
import { useIfMobile } from "../../../utils/responsive";
import { navigate, navigateBack } from "../../../utils/history";
import { routes } from "../../../router/routes";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import { ReactComponent as TrustedDevLogo } from "../../../assets/images/td-logo.svg";
import { ReactComponent as TrustedDevSymbol } from "../../../assets/images/td-symbol.svg";
import { ReactComponent as AiIcon } from "../../../assets/images/ai-icon.svg";
import { ReactComponent as BackArrowIcon } from "../../../assets/images/back-arrow-icon.svg";
import { useGetUserQuery } from "../../../queries/User";
import Avatar from "../Avatar/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAIActive } from "../../../store/selectors/App";
import { toggleIsAIActive } from "../../../store/slices/App";

const Navigation = () => {
  const { isFetched, data } = useGetUserQuery();
  const isAIActive = useSelector(selectIsAIActive);
  const isMobile = useIfMobile();
  const location = useLocation();
  const dispath = useDispatch();

  const isAccountPage = location.pathname.includes("account");

  const toggle = () => dispath(toggleIsAIActive());

  return (
    <nav className="top-navigation container-fluid">
      <div className={`top-navigation__left${isMobile ? (isAccountPage ? "" : " order-1") : ""}`}>
        {isAccountPage && (
          <BackArrowIcon className="back-icon pointer no-tap-highlight" onClick={navigateBack} />
        )}
      </div>
      <div
        className={`top-navigation__logo${
          isMobile
            ? isAccountPage
              ? " justify-content-center"
              : " justify-content-start order-0"
            : ""
        }`}
      >
        {isMobile ? (
          <TrustedDevSymbol
            className="state-transition pointer no-tap-highlight"
            onClick={() => navigate(routes.home)}
          />
        ) : (
          <TrustedDevLogo
            className="state-transition pointer no-tap-highlight"
            onClick={() => navigate(routes.home)}
          />
        )}
      </div>
      <div className="top-navigation__right">
        {!isAccountPage && (
          <>
            <div className="top-navigation__toggle">
              <AiIcon />
              <ToggleSwitch
                active={isAIActive}
                onToggle={toggle}
                className="ai-switch no-tap-highlight"
                label="AI"
              />
            </div>
            {isFetched && (
              <div className="top-navigation__profile" onClick={() => navigate(routes.account)}>
                <Avatar
                  src={data?.avatar}
                  alt="User Avatar"
                  backgroundColorVar="--color-suez-canal"
                />
                {!isMobile && <span>{`${data?.first_name} ${data?.last_name}`}</span>}
              </div>
            )}
          </>
        )}
      </div>
    </nav>
  );
};

export default Navigation;
