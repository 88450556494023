import "./ForgotPasswordForm.css";
import { Field, Form, Formik } from "formik";
import { forgotPasswordRules } from "../../../../validation/auth";
import UnderlinedInputField from "../../../shared/inputs/UnderlinedInputField/UnderlinedInputField";
import Button from "../../../shared/Button/Button";
import { useDispatch } from "react-redux";
import { resetPasswordOTPRequest } from "../../../../store/slices/Auth";

const ForgotPasswordForm = () => {
  const dispatch = useDispatch();

  const handleFormSubmit = (values) => dispatch(resetPasswordOTPRequest(values));

  return (
    <Formik
      initialValues={{
        phone_number: "",
      }}
      onSubmit={handleFormSubmit}
      validationSchema={forgotPasswordRules}
    >
      {() => (
        <Form className="forgot-password-form">
          <div className="d-flex flex-column">
            <Field
              label="Mobile Number"
              type="text"
              name="phone_number"
              placeholder="Enter your mobile number"
              autoComplete="off"
              component={UnderlinedInputField}
            />
            <Button type="submit" title="CONTINUE" backgroundColorVar="--color-super-rare-jade" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
