import { createSelector } from "reselect";

const appSlice = (state) => state.AppSlice;

export const selectToken = createSelector(appSlice, (state) => state.token);

export const selectUser = createSelector(appSlice, (state) => state.user);

export const selectIsAuthenticated = createSelector(appSlice, (state) => !!state.token);

export const selectIsAppLoading = createSelector(
  appSlice,
  (state) => state.waitingResponseCount > 0
);

export const selectActiveMobileTab = createSelector(appSlice, (state) => state.activeMobileTab);

export const selectIsAIActive = createSelector(appSlice, (state) => state.ai.isActive);

export const selectIsAIModalVisible = createSelector(appSlice, (state) => state.ai.isModalVisible);
