import PropTypes from "prop-types";
import "./ToggleSwitch.css";

const ToggleSwitch = ({ active = false, onToggle = () => {}, className, label = "Toggle" }) => {
  return (
    <div className={"toggle-switch " + className}>
      <input type="checkbox" id="switch" checked={active} onChange={onToggle} />
      <label htmlFor="switch">{label}</label>
    </div>
  );
};

ToggleSwitch.propTypes = {
  active: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
};

export default ToggleSwitch;
