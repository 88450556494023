import "./HorizontalGraphTooltip.css";

const HorizontalGraphTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="horizontal-graph-tooltip">
        <span className="month">{`${label} ${payload[0].payload?.year}`}</span>
        <div className="data">
          <span style={{ color: `${payload[0].color}` }}>{`Upvoted: ${payload[0].value}`}</span>
          <span style={{ color: `${payload[1].color}` }}>{`Downvoted: ${payload[1].value}`}</span>
          <span style={{ color: `${payload[2].color}` }}>{`Interviewed: ${payload[2].value}`}</span>
          <span style={{ color: `${payload[3].color}` }}>{`Hired: ${payload[3].value}`}</span>
        </div>
      </div>
    );
  }

  return null;
};

export default HorizontalGraphTooltip;
