import request from "../utils/request";

const RECRUITER_API_URL = "recruiter/api";

const CampaignService = {
  getCampaigns: () =>
    request({
      url: `${RECRUITER_API_URL}/getMyCampaigns`,
      method: "get",
    }),
  updateCampaignStatus: (campaign_id, status) =>
    request({
      url: `${RECRUITER_API_URL}/updateCampaignStatus/${campaign_id}`,
      method: "put",
      data: { status },
    }),
  requestCampaign: () =>
    request({
      url: `${RECRUITER_API_URL}/campaignRequest`,
      method: "post",
    }),
};

export default CampaignService;
