import "./LoginRouter.css";
import { Switch, Redirect, Route } from "react-router-dom";
import PublicRoute from "../PublicRoute";
import { ReactComponent as BackArrowIcon } from "../../assets/images/back-arrow-icon.svg";
import { routes } from "../routes";
import { IfDesktop, useIfMobile } from "../../utils/responsive";
import Login from "../../containers/auth/Login/Login";
import ForgotPassword from "../../containers/auth/ForgotPassword/ForgotPassword";
import { routesPrefix } from "../routes";
import { navigateBack } from "../../utils/history";

const LoginRouter = () => {
  const isMobile = useIfMobile();

  return (
    <div className="login d-flex flex-grow-1 justify-content-center row g-0">
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex flex-column flex-grow-1 px-3 content">
        <span
          onClick={navigateBack}
          className="link d-flex align-self-start justify-content-center back-to-site pointer no-tap-highlight"
        >
          <BackArrowIcon />
          {!isMobile && "Back to site"}
        </span>
        <Switch>
          <Route exact path={routes.login} component={Login} />
          <PublicRoute exact path={routes.forgotPassword} component={ForgotPassword} />
          <Redirect from={`${routesPrefix.auth}*`} to={routes.login} />
        </Switch>
      </div>
      <IfDesktop>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 banner">
          <div className="banner-overlay">
            <h1>Suspendisse ut sapien id neque.</h1>
            <hr />
            <p>
              Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis
              egestas.
            </p>
          </div>
        </div>
      </IfDesktop>
    </div>
  );
};

export default LoginRouter;
