import "./CampaignList.css";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars-2";
import { motion } from "framer-motion";
import CampaignCard from "../CampaignCard/CampaignCard";
import { useGetCampaignsQuery, useToggleCampaignStatusQuery } from "../../../queries/Campaign";
import { setSelectedCampaign } from "../../../store/slices/Campaign";
import { selectSelectedCampaign } from "./../../../store/selectors/Campaign";
import {
  ScrollThumbVertical,
  ScrollTrackVertical,
} from "../../shared/CustomScrollBar/CustomScrollBar";
import Button from "./../../shared/Button/Button";
import { requestCampaign } from "../../../store/saga/actions/Campaign";
import { sidebarContentVariants } from "../../../utils/animation";

const CampaignList = () => {
  const { data } = useGetCampaignsQuery();
  const { mutate: onToggleStatus } = useToggleCampaignStatusQuery();
  const selectedCampaign = useSelector(selectSelectedCampaign);
  const dispatch = useDispatch();

  const selectCampaign = (campaign) => dispatch(setSelectedCampaign(campaign));
  const handleRequestCampaign = () => dispatch(requestCampaign());

  return (
    <motion.div
      layout
      variants={sidebarContentVariants}
      initial={"hidden"}
      animate={"show"}
      exit={"hidden"}
      className="campaign-list"
    >
      <div className="request-campaign">
        <Button title="REQUEST A CAMPAIGN" onClick={handleRequestCampaign} />
      </div>
      <Scrollbars
        id="campaignList"
        className="content"
        renderThumbVertical={ScrollThumbVertical}
        renderTrackVertical={ScrollTrackVertical}
      >
        <div className="wrapper">
          {!!data?.length &&
            data.map((campaign) => (
              <CampaignCard
                key={campaign?.campaign_id}
                name={campaign?.campaign_name}
                date={
                  campaign?.created_datetime &&
                  new Date(campaign?.created_datetime).toLocaleDateString()
                }
                description={campaign?.campaign_description}
                status={campaign?.status}
                isSelected={selectedCampaign?.campaign_id === campaign?.campaign_id}
                onClick={() => selectCampaign(campaign)}
                onToggleStatus={(e) => {
                  e.stopPropagation();
                  onToggleStatus(campaign);
                }}
              />
            ))}
        </div>
      </Scrollbars>
    </motion.div>
  );
};

export default CampaignList;
