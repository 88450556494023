import PropTypes from "prop-types";
import "./StatsSectionHeader.css";

const StatsSectionHeader = ({ icon, figure, title, containerClassName }) => {
  const Icon = icon;
  return (
    <div
      className={"stats-section-header" + (!!containerClassName ? ` ${containerClassName}` : "")}
    >
      <Icon className="stats-section-header__icon" />
      <span className="stats-section-header__figure">
        {+figure < 10 && +figure >= 0 ? `0${figure}` : figure}
      </span>
      <span className="stats-section-header__title">{title}</span>
    </div>
  );
};

StatsSectionHeader.propTypes = {
  icon: PropTypes.object.isRequired,
  figure: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
};

export default StatsSectionHeader;
