import "./Register.css";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as BackArrowIcon } from "../../assets/images/back-arrow-icon.svg";
import { routes } from "../../router/routes";
import { IfDesktop } from "../../utils/responsive";
import { ReactComponent as Logo } from "../../assets/images/td-logo.svg";
import RegisterArtwork1 from "../../assets/images/register-artwork-1.svg";
import RegisterArtwork2 from "../../assets/images/register-artwork-2.svg";
import RegisterArtwork3 from "../../assets/images/register-artwork-3.svg";
import RegisterArtwork4 from "../../assets/images/register-artwork-4.svg";
import BasicInformationForm from "../../components/forms/register/BasicInformation/BasicInformationForm";
import Questionnaire from "./Questionnaire/Questionnaire";
import { selectIsRegistrationTouched, selectRegistrationStep } from "../../store/selectors/Auth";
import CreatePasswordForm from "../../components/forms/register/CreatePassword/CreatePasswordForm";
import Congratulations from "../../components/forms/register/Congratulations/Congratulations";
import { resetAuthData } from "../../store/slices/Auth";
import { handleBrowserReload, navigate } from "./../../utils/history";

const RegisterRouter = () => {
  const registrationStep = useSelector(selectRegistrationStep);
  const isRegistrationTouched = useSelector(selectIsRegistrationTouched);
  const dispatch = useDispatch();

  const handleGoBack = useCallback(() => {
    if (!isRegistrationTouched) {
      navigate(routes.login);
    } else if (registrationStep === 4) {
      dispatch(resetAuthData());
    } else if (
      window.confirm("Are you sure you want to leave before finishing the registration?")
    ) {
      dispatch(resetAuthData());
    } else {
      navigate(routes.register);
    }
  }, [dispatch, isRegistrationTouched, registrationStep]);

  const handleNavigateToLogin = () => {
    if (!isRegistrationTouched) {
      navigate(routes.login);
    } else if (
      window.confirm("Are you sure you want to leave before finishing the registration?")
    ) {
      dispatch(resetAuthData());
    }
  };

  const getActiveStepIndicatorClass = (step = 1) =>
    registrationStep >= step ? " active-step" : "";

  const getTitleByStep = (step) => {
    switch (step) {
      case 1:
      case 2:
      case 3:
        return "Requirements Personalization Survey";
      case 4:
        return "Congratulations. All Setup!";
      default:
        return "TrustedDev Account Registration";
    }
  };

  const getArtworkByStep = (step) => {
    switch (step) {
      case 1:
      case 2:
        return RegisterArtwork2;
      case 3:
        return RegisterArtwork3;
      case 4:
        return RegisterArtwork4;
      default:
        return RegisterArtwork1;
    }
  };

  const renderByStep = (step) => {
    switch (step) {
      case 0:
        return <BasicInformationForm />;
      case 1:
      case 2:
        return <Questionnaire />;
      case 3:
        return <CreatePasswordForm />;
      case 4:
        return <Congratulations />;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isRegistrationTouched && registrationStep !== 4) {
      window.addEventListener("beforeunload", handleBrowserReload);
    }
    window.addEventListener("popstate", handleGoBack);

    return () => {
      if (isRegistrationTouched && registrationStep !== 4) {
        window.removeEventListener("beforeunload", handleBrowserReload);
      }
      window.removeEventListener("popstate", handleGoBack);
    };
  }, [handleGoBack, isRegistrationTouched, registrationStep]);

  useEffect(() => {
    // duplicate route in order to have better control over browser's back button
    navigate(routes.register);
  }, []);

  return (
    <div className="register">
      <IfDesktop>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-between left-half">
          <div className="d-flex flex-column justify-content-center logo">
            <Logo className="align-self-start flex-shrink-0 mx-5" />
          </div>
          <div
            className="artwork"
            style={{
              backgroundImage: `url(${getArtworkByStep(registrationStep)})`,
            }}
          />
          <div className="mb-5 mx-5 align-self-start">
            <hr />
            <h1>{getTitleByStep(registrationStep)}</h1>
          </div>
        </div>
      </IfDesktop>
      <div className="right-half">
        <div className="d-flex justify-content-between align-self-stretch px-4 header">
          <div className="d-flex flex-grow-1">
            <span onClick={handleGoBack} className="d-flex align-items-center back-to-site pointer">
              <BackArrowIcon />
            </span>
          </div>
          {registrationStep === 0 ? (
            <p className="d-flex  align-items-center m-0">
              I already have account.
              <span onClick={handleNavigateToLogin} className="sign-in pointer no-tap-highlight">
                Sign in
              </span>
              .
            </p>
          ) : (
            <div className="d-flex flex-row flex-grow-1 align-items-end step-indicator">
              <div className={"flex-grow-1" + getActiveStepIndicatorClass(1)}>
                <p className="mt-0">
                  Survey <br />
                  1/2
                </p>
                <span className="state-transition" />
              </div>
              <div className={"flex-grow-1" + getActiveStepIndicatorClass(2)}>
                <p className="mt-0">
                  Survey <br />
                  2/2
                </p>
                <span className="state-transition" />
              </div>
              <div className={"flex-grow-1" + getActiveStepIndicatorClass(3)}>
                <p className="mt-0">
                  Create <br />
                  Password
                </p>
                <span className="state-transition" />
              </div>
              <div className={getActiveStepIndicatorClass(4)}>
                <span className="state-transition" />
              </div>
            </div>
          )}
        </div>
        <div className="d-flex flex-grow-1 justify-content-center content">
          {renderByStep(registrationStep)}
        </div>
      </div>
    </div>
  );
};

export default RegisterRouter;
