import { Router, Switch, Route } from "react-router-dom";
import history from "../utils/history";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { routes, routesPrefix } from "./routes";
import LoginRouter from "./LoginRouter/LoginRouter";
import DashboardRouter from "./DashboardRouter/DashboardRouter";
import GlobalLoader from "../components/shared/GlobalLoader/GlobalLoader";
import Register from "../containers/Register/Register";

const AppRouter = () => {
  return (
    <Router history={history}>
      <GlobalLoader />
      <Switch>
        <Route path={routesPrefix.auth} component={LoginRouter} />
        <PublicRoute path={routesPrefix.register} component={Register} />
        <PrivateRoute path={routes.home} component={DashboardRouter} />
      </Switch>
    </Router>
  );
};

export default AppRouter;
