export const dateTimeLocale = "en-US";

export const dateFormatOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "2-digit",
};

export const timeFormatOptions = {
  minute: "2-digit",
  hour: "numeric",
  hour12: true,
};
