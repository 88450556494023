import { createSelector } from "reselect";

const campaignSlice = (state) => state.CampaignSlice;

export const selectSelectedCampaign = createSelector(
  campaignSlice,
  (state) => state.selectedCampaign
);

export const selectIsCampaignRequestedModalVisible = createSelector(
  campaignSlice,
  (state) => state.isCampaignRequestedModalVisible
);
