import "./QuestionnaireForm.css";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { useFormik } from "formik";
import { motion } from "framer-motion";
import Button from "../../../shared/Button/Button";
import OptionGroup from "../../../shared/inputs/OptionGroup/OptionGroup";
import { questionnaireValidator } from "../../../../validation/register";

const animateFormOptions = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { ease: "easeOut", duration: 0.35 },
};

const QuestionnaireForm = ({ questions, onSubmit, onBack, step }) => {
  const scrollRef = useRef(null);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: questions,
    onSubmit,
    validateOnMount: true,
    validate: questionnaireValidator,
  });

  const handleChange = (e, questionId, optionId) => {
    const { checked, type } = e.target;

    if (type === "checkbox") {
      formik.setFieldValue(`[${questionId}].options[${optionId}]`, {
        ...formik.values[questionId].options[optionId],
        checked,
      });
    } else if (type === "radio") {
      formik.setFieldValue(`[${questionId}].options`, {
        ...Object.values(formik.values[questionId].options).map((option) => {
          if (option.id === optionId) {
            return {
              ...option,
              checked: true,
            };
          } else if (option.isCustom) {
            return {
              ...option,
              checked: false,
              value: "",
            };
          } else {
            return {
              ...option,
              checked: false,
            };
          }
        }),
      });
    }
  };

  const handleCustomChange = (e, questionId, optionId, questionType) => {
    const { checked, type } = e.target;

    if (type === "checkbox") {
      formik.setFieldValue(`[${questionId}].options[${optionId}]`, {
        ...formik.values[questionId].options[optionId],
        value: "",
        checked,
      });
    } else if (type === "text") {
      if (questionType === "radio") {
        formik.setFieldValue(`[${questionId}].options`, {
          ...Object.values(formik.values[questionId].options).map((option) =>
            option.isCustom && option.id === optionId
              ? {
                  ...option,
                  value: e.target.value,
                  checked: !!e.target.value,
                }
              : {
                  ...option,
                  checked: option.id === optionId,
                }
          ),
        });
      } else if (questionType === "checkbox") {
        formik.setFieldValue(`[${questionId}].options[${optionId}]`, {
          ...formik.values[questionId].options[optionId],
          value: e.target.value,
          checked: !!e.target.value,
        });
      }
    }
  };

  useEffect(() => {
    scrollRef?.current?.scrollTo(0, 0);
  }, [step]);
  return (
    <form className="d-flex flex-column flex-grow-1" onSubmit={formik.handleSubmit}>
      <motion.div
        ref={scrollRef}
        {...animateFormOptions}
        className="flex-grow-1 register-form-content"
      >
        <h3 className="pb-4">Please fill out the form below to get started.</h3>
        {Object.values(formik.values).map((question) => (
          <OptionGroup
            index={question?.index}
            key={question?.id}
            {...question}
            onChange={handleChange}
            onCustomChange={handleCustomChange}
            isError={formik.errors[question.id]}
          />
        ))}
      </motion.div>
      <div className="align-self-stretch d-flex justify-content-center px-4 footer">
        <Button
          type="button"
          onClick={() => onBack(formik.values)}
          title="PREVIOUS STEP"
          backgroundColorVar="--color-first-frost"
          titleColorVar="--color-forest-blues"
          margin="0 24px 0 0"
        />
        <Button type="submit" title="CONTINUE >" backgroundColorVar="--color-super-rare-jade" />
      </div>
    </form>
  );
};

QuestionnaireForm.propTypes = {
  questions: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
};

export default QuestionnaireForm;
