import { useMediaQuery } from "react-responsive";

const desktopMinWidth = 1170;
const mobileMaxWidth = 1169;

export const useIfDesktop = () => useMediaQuery({ query: `(min-width: ${desktopMinWidth}px)` });

export const useIfMobile = () => useMediaQuery({ query: `(max-width: ${mobileMaxWidth}px)` });

export const IfDesktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: desktopMinWidth });
  return isDesktop ? children : null;
};

export const IfMobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: mobileMaxWidth });
  return isMobile ? children : null;
};
