import "./CreatePasswordForm.css";
import { useDispatch } from "react-redux";
import { Field, Form, Formik } from "formik";
import { motion } from "framer-motion";
import Button from "../../../shared/Button/Button";
import { UnderlinedPasswordInputField } from "../../../shared/inputs/UnderlinedInputField/UnderlinedInputField";
import { createNewPasswordRules } from "../../../../validation/auth";
import { createPasswordRequest, registrationGoBack } from "../../../../store/slices/Auth";

const animateFormOptions = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { ease: "easeInOut", duration: 0.35 },
};

const CreatePasswordForm = () => {
  const dispatch = useDispatch();

  const handleFormSubmit = (values) => dispatch(createPasswordRequest(values));
  const handleGoBack = () => dispatch(registrationGoBack());

  return (
    <Formik
      initialValues={{
        password: "",
        confirm_password: "",
      }}
      onSubmit={handleFormSubmit}
      validationSchema={createNewPasswordRules}
    >
      {() => (
        <Form className="create-password-form text-center d-flex flex-column">
          <motion.div
            {...animateFormOptions}
            className="d-flex flex-column flex-grow-1 register-form-content"
          >
            <div className="flex-grow-1">
              <h3 className="pb-4 text-start">Please create your password.</h3>
              <Field
                name="password"
                type="password"
                placeholder="Enter your password"
                autoComplete="new-password"
                label="Password"
                component={UnderlinedPasswordInputField}
              />
              <Field
                name="confirm_password"
                type="password"
                placeholder="Confirm your password"
                autoComplete="new-password"
                label="Confirm Password"
                component={UnderlinedPasswordInputField}
              />
            </div>
            <div className="text-start password-policy">
              <h4>Password Requirements</h4>
              <p>• Be a minimum of eight (8) characters in length.</p>
              <p>
                • Contain at least one (1) character from three (3) of the following categories:
                Uppercase letter (A-Z) Lowercase letter (a-z) Digit (0-9) Special character #(~`! …
              </p>
              <p>• Be memorized; if a password is written down it must be secure.</p>
            </div>
          </motion.div>
          <div className="align-self-stretch d-flex justify-content-center px-4 footer">
            <Button
              type="button"
              onClick={handleGoBack}
              title="PREVIOUS STEP"
              backgroundColorVar="--color-first-frost"
              titleColorVar="--color-forest-blues"
              margin="0 24px 0 0"
            />
            <Button type="submit" title="FINISH" backgroundColorVar="--color-super-rare-jade" />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreatePasswordForm;
