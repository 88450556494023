import { createSelector } from "reselect";

const authSlice = (state) => state.AuthSlice;

export const selectIsLoginPending = createSelector(authSlice, (state) => state.isLoginPending);

export const selectIsRegisterPending = createSelector(
  authSlice,
  (state) => state.registration.isRegisterPending
);
export const selectRegistrationStep = createSelector(authSlice, (state) => state.registration.step);
export const selectIsRegistrationTouched = createSelector(
  authSlice,
  (state) => state.registration.isTouched
);

export const selectIsLogoutPending = createSelector(authSlice, (state) => state.isLogoutPending);
export const selectIsCreateNewPasswordPending = createSelector(
  authSlice,
  (state) => state.isCreateNewPasswordPending
);
export const selectIsChangePassword = createSelector(authSlice, (state) => state.isChangePassword);
export const selectIsResetPasswordPending = createSelector(
  authSlice,
  (state) => state.isResetPasswordPending
);
export const selectIsCreateNewPassword = createSelector(
  authSlice,
  (state) => state.isCreateNewPassword
);

export const selectIsOTPPending = createSelector(authSlice, (state) => state.otp.isPending);
export const selectIsOTPResendPending = createSelector(
  authSlice,
  (state) => state.otp.isResendPending
);
export const selectOTPPhone = createSelector(authSlice, (state) => state.otp.phone);
export const selectOTPEmail = createSelector(authSlice, (state) => state.otp.email);

export const selectCurrentQuestionnaire = createSelector(authSlice, (state) =>
  state.registration.step === 1 ? state.questionnaire.partOne : state.questionnaire.partTwo
);
export const selectQuestionnaire = createSelector(authSlice, (state) => state.questionnaire);
export const selectIsQuestionnairePending = createSelector(
  authSlice,
  (state) => state.questionnaire.isQuestionnairePending
);

export const selectBasicInfo = createSelector(authSlice, (state) => state.basicInfo);
