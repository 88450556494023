import PropTypes from "prop-types";
import { ReactComponent as PlayIcon } from "../../../assets/images/play-icon.svg";
import { ReactComponent as PauseIcon } from "../../../assets/images/pause-icon.svg";
import "./CampaignCard.css";

const CampaignCard = ({ name, date, description, status, isSelected, onClick, onToggleStatus }) => {
  return (
    <div
      className={"campaign-card state-transition" + (isSelected ? " selected" : "")}
      onClick={onClick}
    >
      <div className="campaign-card__header">
        <span className="campaign-card__name text-one-line">{name}</span>
        <span className="campaign-card__date">{date}</span>
      </div>
      <p className="campaign-card__description">{description}</p>
      {status ? (
        <PauseIcon
          className="campaign-card__pause-icon state-transition"
          onClick={onToggleStatus}
        />
      ) : (
        <PlayIcon className="campaign-card__play-icon state-transition" onClick={onToggleStatus} />
      )}
    </div>
  );
};

CampaignCard.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onToggleStatus: PropTypes.func.isRequired,
};

export default CampaignCard;
