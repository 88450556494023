import "./ActiveCampaignBar.css";
import { useDispatch, useSelector } from "react-redux";
import Button from "./../../shared/Button/Button";
import { ReactComponent as FilterIcon } from "./../../../assets/images/filter-icon.svg";
import { clearSelectedCampaign } from "../../../store/slices/Campaign";
import { selectSelectedCampaign } from "./../../../store/selectors/Campaign";

const ActiveCampaignBar = () => {
  const campaign = useSelector(selectSelectedCampaign);
  const dispatch = useDispatch();
  const onShowAll = () => dispatch(clearSelectedCampaign());

  return (
    campaign && (
      <div className="active-campaign-bar d-flex align-items-center justify-content-between">
        <FilterIcon className="flex-shrink-0" />
        <span className="title text-one-line flex-grow-1">{campaign?.campaign_name}</span>
        <Button onClick={onShowAll} title="SHOW ALL" />
      </div>
    )
  );
};

export default ActiveCampaignBar;
