import "./CandidateList.css";
import PropTypes from "prop-types";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useDispatch, useSelector } from "react-redux";

import CandidateListItem from "../CandidateListItem/CandidateListItem";
import EmptyCandidateListItem from "./../EmptyCandidateListItem/EmptyCandidateListItem";
import CandidatePreviewCard from "../CandidatePreviewCard/CandidatePreviewCard";
import {
  ScrollThumbVertical,
  ScrollTrackVertical,
} from "../../shared/CustomScrollBar/CustomScrollBar";
import {
  useGetCandidatesQuery,
  useToggleCandidateFavoriteStatusQuery,
} from "../../../queries/Candidate";
import {
  selectCandidateFilterFavorites,
  selectCandidateFilterProfileStatus,
  selectCandidateSearchText,
} from "../../../store/selectors/CandidateFilter";
import { selectSelectedCampaign } from "../../../store/selectors/Campaign";
import { clearWhiteSpacesAndConvertToLowerCase } from "../../../utils/misc";
import { selectPreviewCandidate } from "../../../store/selectors/Candidate";
import { setPreviewCandidate } from "../../../store/slices/Candidate";
import SearchFilterBar from "./../SearchFilterBar/SearchFilterBar";
import NewMatchBar from "./../NewMatchBar/NewMatchBar";
import { useGetNewMatchCandidatesQuery } from "../../../queries/NewMatches";

const CandidateList = ({ containerClassName }) => {
  const selectedCampaign = useSelector(selectSelectedCampaign);
  const isFavorites = useSelector(selectCandidateFilterFavorites);
  const profileStatus = useSelector(selectCandidateFilterProfileStatus);
  const searchText = useSelector(selectCandidateSearchText);
  const previewCandidate = useSelector(selectPreviewCandidate);
  const [previewExited, setPreviewExited] = useState(true);
  const dispatch = useDispatch();

  const candidateFilterParams = {
    campaign_id: selectedCampaign?.campaign_id || null,
    profile_status: profileStatus,
    is_favourite: isFavorites,
  };

  const { data: newMatches } = useGetNewMatchCandidatesQuery();
  const { isLoading, data } = useGetCandidatesQuery(candidateFilterParams);
  const [localData, setLocalData] = useState(data);

  const { mutate: toggleFavoriteStatus } =
    useToggleCandidateFavoriteStatusQuery(candidateFilterParams);

  const selectCandidate = (candidate) => dispatch(setPreviewCandidate(candidate));

  const onFavorite = (event, candidate) => {
    event.stopPropagation();
    toggleFavoriteStatus(candidate);
  };

  const onCandidatePreview = (candidate) => {
    if (previewExited && !window.getSelection().toString().length) {
      setPreviewExited(false);
      selectCandidate(candidate);
    }
  };

  const renderCandidateList = (data) =>
    !!data?.length ? (
      data.map((candidate) => (
        <CandidateListItem
          key={candidate?.candidate_profile_status_id}
          data={candidate}
          onClick={() => onCandidatePreview(candidate)}
          onFavorite={(event) => onFavorite(event, candidate)}
        />
      ))
    ) : !isLoading ? (
      <EmptyCandidateListItem />
    ) : null;

  useEffect(() => {
    setLocalData(
      data?.filter((candidate) => {
        const search = clearWhiteSpacesAndConvertToLowerCase(searchText);
        const firstName = clearWhiteSpacesAndConvertToLowerCase(candidate?.first_name);
        const lastName = clearWhiteSpacesAndConvertToLowerCase(candidate?.last_name);
        const techStack = clearWhiteSpacesAndConvertToLowerCase(candidate?.tech_stack);
        const state = clearWhiteSpacesAndConvertToLowerCase(candidate?.state);
        const city = clearWhiteSpacesAndConvertToLowerCase(candidate?.city);
        const country = clearWhiteSpacesAndConvertToLowerCase(candidate?.country);

        return (
          (firstName + lastName).includes(search) ||
          (lastName + firstName).includes(search) ||
          techStack.includes(search) ||
          state.includes(search) ||
          city.includes(search) ||
          country.includes(search)
        );
      })
    );
  }, [searchText, data, setLocalData]);

  return (
    <>
      <SearchFilterBar total={!!searchText ? localData?.length : data?.length} />
      <NewMatchBar data={newMatches} />
      <Scrollbars
        id="candidateList"
        className="candidate-list"
        renderThumbVertical={ScrollThumbVertical}
        renderTrackVertical={ScrollTrackVertical}
        renderThumbHorizontal={() => <div />}
        renderTrackHorizontal={() => <div />}
      >
        <AnimateSharedLayout type="crossfade">
          <motion.div
            id="candidateGrid"
            className={
              "candidate-list__grid" + (!!containerClassName ? ` ${containerClassName}` : "")
            }
          >
            {renderCandidateList(!!searchText ? localData : data)}
          </motion.div>
          <AnimatePresence onExitComplete={() => setPreviewExited(true)}>
            {previewCandidate && (
              <CandidatePreviewCard
                key={previewCandidate?.candidate_profile_status_id}
                data={previewCandidate}
                onClose={() => selectCandidate(null)}
                onFavorite={(event) => onFavorite(event, previewCandidate)}
              />
            )}
          </AnimatePresence>
        </AnimateSharedLayout>
      </Scrollbars>
    </>
  );
};

CandidateList.propTypes = {
  containerClassName: PropTypes.string,
};

export default CandidateList;
