import request, { attachQueryParams } from "../utils/request";

const RECRUITER_API_URL = "recruiter/api";

const InterviewService = {
  getInterviews: (queryParams = {}) =>
    request({
      url: attachQueryParams(`${RECRUITER_API_URL}/getMyInterviews`, queryParams),
      method: "get",
    })
};

export default InterviewService;
