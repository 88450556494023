import "./ForgotPassword.css";
import { AnimatePresence, AnimateSharedLayout, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Logo } from "../../../assets/images/td-logo-original.svg";
import ForgotPasswordForm from "../../../components/forms/auth/ForgotPassword/ForgotPasswordForm";
import { selectOTPPhone } from "../../../store/selectors/Auth";
import TwoStepVerificationForm from "../../../components/forms/account/TwoStepVerification/TwoStepVerificationForm";
import { resetPasswordOTPRequest, verifyResetPasswordOTPRequest } from "../../../store/slices/Auth";
import CreateNewPasswordForm from "./../../../components/forms/account/CreateNewPassword/CreateNewPasswordForm";
import { selectIsCreateNewPassword } from "./../../../store/selectors/Auth";

const animateHeadingOptions = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { ease: "easeOut", duration: 0.35 },
};

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const otpPhone = useSelector(selectOTPPhone);
  const isCreateNewPassword = useSelector(selectIsCreateNewPassword);

  const verifyResetPasswordOTP = ({ code }) =>
    dispatch(verifyResetPasswordOTPRequest({ reset_password_otp: code, phone_number: otpPhone }));

  const resendOTP = () => dispatch(resetPasswordOTPRequest({ phone_number: otpPhone }));

  return (
    <AnimateSharedLayout>
      <div className="w-100 d-flex flex-column flex-grow-1 justify-content-center align-items-center forgot-password-container">
        <div className="title-section text-center">
          <motion.div layout>
            <Logo className="logo w-75" />
          </motion.div>
          <AnimatePresence exitBeforeEnter>
            {isCreateNewPassword ? (
              <motion.h3 {...animateHeadingOptions}>Please create a new password.</motion.h3>
            ) : !!otpPhone ? (
              <motion.h3 {...animateHeadingOptions}>Password Reset</motion.h3>
            ) : (
              <motion.h3 {...animateHeadingOptions}>Please enter your mobile numer.</motion.h3>
            )}
          </AnimatePresence>
        </div>
        <AnimatePresence exitBeforeEnter>
          {isCreateNewPassword ? (
            <CreateNewPasswordForm key={"create-new-password-form"} />
          ) : !!otpPhone ? (
            <TwoStepVerificationForm
              key={"2fa-form"}
              onSubmit={verifyResetPasswordOTP}
              onResend={resendOTP}
              submitLabel="RESET PASSWORD"
              phone={otpPhone}
            />
          ) : (
            <ForgotPasswordForm key={"forgot-password-form"} />
          )}
        </AnimatePresence>
      </div>
    </AnimateSharedLayout>
  );
};

export default ForgotPassword;
