import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  profileStatus: null,
  isFavorites: null,
  searchText: "",
};

const CandidateFilterSlice = createSlice({
  name: "candidateFilter",
  initialState,
  reducers: {
    setCandidateFilterProfileStatus(state, { payload }) {
      state.profileStatus = state.profileStatus !== payload ? payload : initialState.profileStatus;
    },
    toggleCandidateFilterFavorites(state) {
      state.isFavorites = state.isFavorites ? null : 1;
    },
    setCandidateSearchText(state, { payload }) {
      state.searchText = payload;
    },
  },
});

export const {
  setCandidateFilterProfileStatus,
  toggleCandidateFilterFavorites,
  setCandidateSearchText,
} = CandidateFilterSlice.actions;
export default CandidateFilterSlice.reducer;
