import "./CampaignRequestedModal.css";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { openPopupWidget } from "react-calendly";

import Button from "./../../shared/Button/Button";
import { useGetUserQuery } from "../../../queries/User";
import { selectIsCampaignRequestedModalVisible } from "../../../store/selectors/Campaign";
import { setCampaignRequestedModalVisibility } from "../../../store/slices/Campaign";
import config from "./../../../config";

const CampaignRequestedModal = () => {
  const { isSuccess, data } = useGetUserQuery();
  const isVisible = useSelector(selectIsCampaignRequestedModalVisible);
  const dispatch = useDispatch();

  const closeModal = () => dispatch(setCampaignRequestedModalVisibility(false));

  const showCalendly = () => {
    closeModal();
    openPopupWidget({
      url: config.calendly.url,
      prefill: {
        name:
          isSuccess && !!data?.first_name && !!data?.last_name
            ? data?.first_name + " " + data?.last_name
            : "",
        email: data?.email_id,
      },
    });
  };

  return (
    isVisible && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="campaign-requested-modal"
      >
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          className="d-flex flex-column justify-content-center text-center content"
        >
          <h1>Campaign Requested</h1>
          <p>
            Thank you for requesting a new campaign. Your Trusted Dev concierge will be in touch
            shortly
          </p>
          <Button
            className="align-self-center"
            title="SCHEDULE A ZOOM MEETING WITH YOUR CONCIERGE"
            onClick={showCalendly}
          />
        </motion.div>
      </motion.div>
    )
  );
};

export default CampaignRequestedModal;
