/* eslint-disable react-hooks/exhaustive-deps */
import "../../../src/components/newMatches/NewMatchesList/NewMatchesList.css";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AnimatePresence, motion, useAnimation } from "framer-motion";

import { ReactComponent as LikeIcon } from "../../../src/assets/images/big-like-icon.svg";
import { ReactComponent as DislikeIcon } from "../../../src/assets/images/big-dislike-icon.svg";
import { setNewMatchesAvailable, setShowNewMatches } from "../../../src/store/slices/Candidate";
import {
    useGetNewMatchCandidatesQuery,
    useVoteDownCandidateQuery,
    useVoteUpCandidateQuery,
} from "../../queries/NewMatches";
import { useLocation } from "react-router-dom";
import NewMatchesCard from "./NewMatchesCard/NewMatchesCard";
import HorizontalGraph from "../stats/HorizontalGraph/HorizontalGraph";
import { newMatchesVariants } from "../../utils/animation"; 

export const NewMatchData = () => {
    const dislikeControls = useAnimation();
    const likeControls = useAnimation();
    const { data: candidates } = useGetNewMatchCandidatesQuery();
    const { mutate: voteUp } = useVoteUpCandidateQuery();
    const { mutate: voteDown } = useVoteDownCandidateQuery();
    const [index, setIndex] = useState(0);
    const [exitX, setExitX] = useState("100%");
    const [exitY, setExitY] = useState(0);
    const [isFromEmail, setisFromEmail] = useState(false); 
    const [isFromEmail1, setisFromEmail1] = useState([]); 
    const dispatch = useDispatch();
    const location = useLocation();
    const data = location.search; 
    let candidate_profile_status_id = data.split('&')[0].split('=')[1] 
    let profile_status = data.split('&')[1].split('=')[1]
    let client_id = data.split('&')[2].split('=')[1]; 
    console.log('candidate_profile_status_id', candidate_profile_status_id);
    console.log('profile_status', profile_status);
    console.log('client_id', client_id); 
    console.log(candidates);
    var final= [];
    // if(candidates){
    //     let newArray = candidates.filter(function (el) {
    //     return (el.candidate_profile_status_id).toString() === candidate_profile_status_id }); 
    //     final = newArray;
    // }
    // console.log(final);
    // console.log(profile_status == 2);
    // if(profile_status == 2){
    //     console.log('status if', profile_status);
    //         voteUp(final);
    // }else {
    //     console.log('status else', profile_status);
    //         voteDown(final);
    // }


    const onBack = useCallback(() => dispatch(setShowNewMatches(false)), [dispatch]);

    const initialIconAnimation = (controls) => {
        controls.start({
            rotate: [0, 40, -40, 40, -40, 0],
            transition: {
                delay: 2,
                duration: 1.5,
                ease: "easeInOut",
                times: [0, 0.15, 0.3, 0.45, 0.75, 1],
                repeat: Infinity,
                repeatDelay: 4,
            },
        });
    };

    const scaleIconAnimation = (controls) => {
        controls.stop();
        controls.start({ rotate: 0, scale: 2 });
    };

    const resetIconAnimation = async (controls) => {
        await controls.start({
            scale: 1,
        });
        initialIconAnimation(controls);
    };

    const handleDislike = () => {
        setIndex(index + 1);
        voteDown(candidates[0]);
    };

    const handleLike = () => {
        setIndex(index + 1);
        voteUp(candidates[0]);
    };

    const onBackHandler = () => {
        window.location = "/";
    }

    useEffect(() => {
        if (candidates?.length === 0) {
            setTimeout(() => {
                onBack();
                dispatch(setNewMatchesAvailable(false));
            }, 400);
        }
        if (location.pathname.includes("candidate-profile-cards")) {
            setisFromEmail(true)
        } 
        for(let i in candidates){ 
            if((candidates[i].candidate_profile_status_id).toString() === candidate_profile_status_id){ 
                final.push(candidates[i])
                setisFromEmail1(candidates[i])
                console.log('inside for');
                // eslint-disable-next-line eqeqeq
                if(profile_status == 2){
                    setTimeout(() => {
                        voteUp(candidates[i]);
                    }, 1500);
                } else {
                    setTimeout(() => {
                        voteDown(candidates[i]);
                    }, 1500);
                }
                
            }
            console.log(candidates[i]);
            console.log(isFromEmail1);
        }
    }, [candidates, dispatch, onBack, location]);
    console.log(isFromEmail1);
    return (
        <div className="home d-flex">
            <motion.div className="home__content-wrapper">
                <motion.div className="home__content-container">
                    <motion.div
                        key="new-matches"
                        variants={newMatchesVariants}
                        initial={"hidden"}
                        animate={"show"}
                        exit={"hidden"}
                        className="new-match"
                    >
                        <motion.div className="new-matches-list">
                            {isFromEmail ?
                                <div className="back-button state-transition pointer" onClick={onBackHandler} />
                                :
                                <div className="back-button state-transition pointer" onClick={onBack} />
                            }
                            <div className="angle-background">
                                <svg preserveAspectRatio="none" viewBox="0 0 100 100">
                                    <polygon fill="#133b58" points="100,100 0,0 0,100" opacity="1"></polygon>
                                </svg>
                            </div>
                            <AnimatePresence>
                                {candidates?.length && (
                                    <motion.div
                                        className="dislike-icon pointer"
                                        initial={() => initialIconAnimation(dislikeControls)}
                                        animate={dislikeControls}
                                        exit={{ opacity: 0 }}
                                        onClick={handleDislike}
                                        onMouseEnter={() => {
                                            scaleIconAnimation(dislikeControls);
                                            if (exitX !== -2000) setExitX(-2000);
                                            if (exitY !== 0) setExitY(0);
                                        }}
                                        onMouseLeave={() => resetIconAnimation(dislikeControls)}
                                    >
                                        <DislikeIcon />
                                    </motion.div>
                                )}
                            </AnimatePresence>
                            <div className="cards">
                                <div className="cards-container">
                                    <AnimatePresence initial={false}>
                                        {candidates?.length >= 1 && (
                                            <NewMatchesCard
                                                key={index}
                                                animate={{
                                                    scale: 1,
                                                    y: 0,
                                                    opacity: 1,
                                                }}
                                                transition={{
                                                    y: { duration: 0.5 },
                                                    opacity: { duration: 0.2 },
                                                }}
                                                exitX={exitX}
                                                setExitX={setExitX}
                                                exitY={exitY}
                                                setExitY={setExitY}
                                                index={index}
                                                setIndex={setIndex}
                                                isLast={candidates?.length === 1}
                                                drag={true}
                                                data={isFromEmail1}
                                                onDislike={voteDown}
                                                onLike={voteUp}
                                            />
                                        )}
                                    </AnimatePresence>
                                </div>
                            </div>
                            <AnimatePresence>
                                {candidates?.length && (
                                    <motion.div
                                        className="like-icon pointer"
                                        initial={() => initialIconAnimation(likeControls)}
                                        animate={likeControls}
                                        exit={{ opacity: 0 }}
                                        onClick={handleLike}
                                        onMouseEnter={() => {
                                            scaleIconAnimation(likeControls);
                                            if (exitX !== 2000) setExitX(2000);
                                            if (exitY !== 0) setExitY(0);
                                        }}
                                        onMouseLeave={() => resetIconAnimation(likeControls)}
                                    >
                                        <LikeIcon />
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </motion.div>
                    </motion.div>
                </motion.div>
                <HorizontalGraph containerClassName="home__graph-container" />
            </motion.div>
        </div>
    );
};

export default NewMatchData