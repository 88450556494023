import "./NewMatchBar.css";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../../shared/Button/Button";
import { setShowNewMatches } from "../../../store/slices/Candidate";
import { useIfMobile } from "../../../utils/responsive";

const NewMatchBar = ({ containerClassName, data }) => {
  const isMobile = useIfMobile();
  const dispatch = useDispatch();
  const showNewMatches = () => dispatch(setShowNewMatches(true));

  return (
    <AnimatePresence>
      {!!data?.length && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: "just", duration: 0.5 }}
          className={"new-match-bar" + (!!containerClassName ? ` ${containerClassName}` : "")}
          style={
            isMobile
              ? {
                  position: "fixed",
                  bottom: 46,
                  left: 0,
                  right: 0,
                }
              : {}
          }
        >
          <div className="new-match-bar__message">
            <motion.svg
              animate={{
                rotate: [0, 40, -40, 40, -40, 0],
              }}
              transition={{
                duration: 1.5,
                ease: "easeInOut",
                times: [0, 0.15, 0.3, 0.45, 0.75, 1],
                repeat: Infinity,
                repeatDelay: 1,
              }}
              className="icon"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#B2C8D8"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.00001 16C9.10376 16 9.99907 15.1047 9.99907 14L6.00095 14C6.00095 15.1047 6.89626 16 8.00001 16ZM14.7309 11.3216C14.1272 10.6728 12.9975 9.69688 12.9975 6.5C12.9975 4.07188 11.295 2.12813 8.99938 1.65125L8.99938 1C8.99938 0.447812 8.55188 2.55684e-07 8.00001 0C7.44814 3.21322e-07 7.00064 0.447812 7.00064 1L7.00064 1.65125C4.70502 2.12813 3.00252 4.07187 3.00252 6.5C3.00252 9.69688 1.87283 10.6728 1.26908 11.3216C1.08158 11.5231 0.998459 11.7641 1.00002 12C1.00346 12.5125 1.40565 13 2.00314 13H13.9969C14.5944 13 14.9969 12.5125 15 12C15.0016 11.7641 14.9184 11.5228 14.7309 11.3216Z" />
            </motion.svg>

            <span className="title">New Matches Found</span>
          </div>
          <motion.div
            animate={{
              scale: [1, 0.9, 1],
            }}
            transition={{
              duration: 1,
              ease: "easeInOut",
              times: [0, 0.5, 1],
              repeat: Infinity,
            }}
          >
            <Button
              backgroundColorVar="--color-super-rare-jade"
              title="CHECK THEM OUT"
              onClick={showNewMatches}
            />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

NewMatchBar.propTypes = {
  containerClassName: PropTypes.string,
  data: PropTypes.array,
};

export default NewMatchBar;
