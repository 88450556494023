import "./UpcomingInterviewList.css";
import { motion } from "framer-motion";
import { Scrollbars } from "react-custom-scrollbars-2";
import UpcomingInterviewListItem from "../UpcomingInterviewListItem/UpcomingInterviewListItem";
import {
  ScrollThumbVertical,
  ScrollTrackVertical,
} from "../../shared/CustomScrollBar/CustomScrollBar";
import { sidebarContentVariants, tabContentMobileVariants } from "../../../utils/animation";
import { useIfMobile } from "../../../utils/responsive";
import { useGetInterviewsQuery } from "../../../queries/Interview";


const UpcomingInterviewList = () => {
  const isMobile = useIfMobile();
  const { data } = useGetInterviewsQuery();
  const client_interviews = data && data.client_interviews ? data.client_interviews.map(interview => {
    return {
      ...interview,
      firstName : interview.first_name,
      lastName : interview.last_name,
      description : interview.interview_description,
      link : interview.interview_link,
      date : new Date(interview.interview_date)
    }
  }) : [];
  return (
    <motion.div
      variants={isMobile ? tabContentMobileVariants : sidebarContentVariants}
      initial={"hidden"}
      animate={"show"}
      exit={"hidden"}
      className="upcoming-interview-list"
    >
      {!isMobile && (
        <div className="header">
          <span>Upcoming Interviews</span>
        </div>
      )}
      <Scrollbars
        id="upcomingInterviewList"
        className="content"
        renderThumbVertical={() => ScrollThumbVertical({ color: "#134067" })}
        renderTrackVertical={() => ScrollTrackVertical({ color: "#1E517E" })}
        renderThumbHorizontal={() => <div />}
        renderTrackHorizontal={() => <div />}
        hideTracksWhenNotNeeded={isMobile}
      >
        <div className="wrapper">
          {client_interviews && client_interviews.length > 0 && client_interviews.map((interview) => (
            <UpcomingInterviewListItem key={interview.id} data={interview} />
          ))}
        </div>
      </Scrollbars>
    </motion.div>
  );
};

export default UpcomingInterviewList;
