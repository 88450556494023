import request from "../utils/request";

const USER_API_URL = "user/api";

const UserService = {
  getUser: () =>
    request({
      url: `${USER_API_URL}/getUserProfile`,
      method: "get",
    }),
  uploadFile: (image) => {
    const formData = new FormData();
    formData.append("files", image);

    return request({
      url: `user/upload/file/Clients`,
      method: "put",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
  },
  removeUserProfilePicture: () =>
    request({
      url: `${USER_API_URL}/removeUserProfilePicture`,
      method: "delete",
    }),
  updateUser: (
    {
      avatar, // don't remove it, second argument of this function handles avatar's file name
      country,
      state,
      city,
      notes,
      user_id,
      ...data
    },
    avatarFileName = null
  ) => {
    return request({
      url: `${USER_API_URL}/updateUserProfile`,
      method: "post",
      data: avatarFileName ? { ...data, avatar: avatarFileName } : data,
    });
  },
};

export default UserService;
