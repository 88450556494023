import config from "../config";

export const routesPrefix = { auth: "/auth", register: "/register" };

export const routes = {
  website: config.common.tdWebsiteUrl,
  home: "/",
  account: "/account",
  candidateProfileCards: "/candidate-profile-cards",
  details: "/candidate-details",
  login: `${routesPrefix.auth}/login`,
  forgotPassword: `${routesPrefix.auth}/forgot-password`,
  register: routesPrefix.register,
};
