import "./CandidatePreviewCard.css";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import { ReactComponent as HeartIcon } from "../../../assets/images/heart-icon.svg";
import { ReactComponent as ViewResumeIcon } from "../../../assets/images/view-resume-icon.svg";
import { ReactComponent as ChevronIcon } from "../../../assets/images/chevron-icon.svg";
import { ReactComponent as StarIcon } from "../../../assets/images/star-icon.svg";
import { ReactComponent as GithubIcon } from "../../../assets/images/github-icon.svg";
import { ReactComponent as LinkedinIcon } from "../../../assets/images/linkedin-icon.svg";
import { ReactComponent as PortfolioIcon } from "../../../assets/images/portfolio-icon.svg";
import Button from "./../../shared/Button/Button";
import { constructLocationString, linkSocialIcon, renderCandidateStatusIcon } from "../helper";
import Avatar from "../../shared/Avatar/Avatar";
import { selectCandidatePreviewCardPosition } from "./../../../store/selectors/Candidate";
import { useLocation } from "react-router-dom";

const animation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transition: { delay: 0 } },
  transition: { type: "just", delay: 0.25 },
};
const CandidatePreviewCard = ({ data, onClose, onFavorite }) => {
  const position = useSelector(selectCandidatePreviewCardPosition);
  const {
    candidate_profile_status_id,
    experience,
    first_name,
    last_name,
    tech_stack,
    city,
    state,
    country,
    hired_datetime,
    profile_status,
    culture_fit,
    educations = [],
    skills = [],
    hobbies = [],
    avatar = undefined,
    github,
    linkedin,
    portfolio,
    is_favourite,
  } = data || {};

  const location = useLocation();

  const page_route = location.pathname;  
  const cardPosition = {
    left: 340,
    maxHeight: 430,
    top: 170,
    width: 856
  }
  const closeCardIfNotTextSelection = () => {
    if (!window.getSelection().toString().length) {
      onClose();
    }
  }; 
  return (
    <motion.div
      layoutId={`card-${candidate_profile_status_id}`}
      className="candidate-preview-card d-flex flex-column pointer no-tap-highlight"
      style={page_route === "/candidate-details" ? cardPosition : position}
      onClick={closeCardIfNotTextSelection}
    >
      <motion.div className="d-flex justify-content-between flex-wrap">
        <motion.span layoutId={`experience${candidate_profile_status_id}`} className="experience">
          {experience + (experience === 1 ? " YR " : " YRS ") + "Experience"}
        </motion.span>
        <div className="action-icon">
          <motion.div layoutId={`status${candidate_profile_status_id}`}>
            {renderCandidateStatusIcon(profile_status)}
          </motion.div>
        </div>
      </motion.div>
      <div className="d-flex justify-content-between flex-wrap personal-info-row">
        <div className="personal-info-col">
          <div className="photo-info-container">
            <motion.div
              layoutId={`photo-container${candidate_profile_status_id}`}
              className="photo-container"
            >
              <ChevronIcon className="chevron" />
              <Avatar
                src={avatar}
                layoutId={`avatar${candidate_profile_status_id}`}
                alt={`${first_name}'s Profile`}
                width={100}
                height={100}
              />
            </motion.div>
            <motion.span {...animation} className="culture-fit">
              Culture Fit
            </motion.span>
            <motion.div {...animation} className="star-icon-group">
              <StarIcon className={`star-icon${culture_fit >= 1 ? " active" : ""}`} />
              <StarIcon className={`star-icon${culture_fit >= 2 ? " active" : ""}`} />
              <StarIcon className={`star-icon${culture_fit >= 3 ? " active" : ""}`} />
            </motion.div>
          </div>
          <div className="d-flex flex-column">
            <motion.span
              layoutId={`first-name${candidate_profile_status_id}`}
              className="first-name d-inline-block align-self-start"
            >
              {first_name}
            </motion.span>
            <motion.span
              layoutId={`last-name${candidate_profile_status_id}`}
              className="last-name d-inline-block align-self-start"
            >
              {last_name}
            </motion.span>
            <motion.span
              layoutId={`title-name${candidate_profile_status_id}`}
              className="title d-inline-block align-self-start"
            >
              {tech_stack}
            </motion.span>
            <motion.span
              layoutId={`location-name${candidate_profile_status_id}`}
              className="location d-inline-block align-self-start"
            >
              {constructLocationString(city, state, country)}
            </motion.span>
            <motion.div
              layoutId={`social-icons${candidate_profile_status_id}`}
              className="social-icons"
            >
              {linkSocialIcon(github, GithubIcon)}
              {linkSocialIcon(linkedin, LinkedinIcon)}
              {linkSocialIcon(portfolio, PortfolioIcon)}
            </motion.div>
          </div>
        </div>
        <motion.div {...animation} className="education-info-col">
          {!!educations.length && (
            <div className="d-flex flex-column">
              <span className="title d-inline-block align-self-start">Education</span>
              <span className="institution d-inline-block align-self-start">
                {educations[0]?.university}
              </span>
              <span className="degree d-inline-block align-self-start">
                {educations[0]?.specialization + ", " + educations[0]?.course}
              </span>
              {educations.length > 1 && <span className="more">{`+${educations.length - 1}`}</span>}
            </div>
          )}
          <Button
            className="view-resume-btn"
            icon={ViewResumeIcon}
            title="VIEW FULL RESUME"
            onClick={(event) => {
              event.stopPropagation();
            }}
            margin="23px 0 0 0"
          />
        </motion.div>
      </div>
      <hr className="divider" />
      <div className="d-flex justify-content-between flex-wrap additional-info-row">
        {!!skills.length && (
          <motion.div {...animation} className="skills-info-col d-flex flex-column">
            <span className="title d-inline-block align-self-start">Skills</span>
            {skills.map(({ id, skill_name }) => (
              <span key={id} className="skill d-inline-block align-self-start">
                - {skill_name}
              </span>
            ))}
          </motion.div>
        )}
        {!!hobbies.length && (
          <motion.div {...animation} className="hobbies-info-col d-flex flex-column">
            <span className="title d-inline-block align-self-start">Hobbies</span>
            {hobbies.map(({ id, hobby_name }) => (
              <span key={id} className="hobby d-inline-block align-self-start">
                - {hobby_name}
              </span>
            ))}
          </motion.div>
        )}
      </div>
      <div className="d-flex justify-content-between flex-wrap hired-date-row">
        <motion.span layoutId={`hired${candidate_profile_status_id}`} className="hired-date">
          {hired_datetime && `Hired ${new Date(hired_datetime).toLocaleDateString()}`}
        </motion.span>
        <div className="favorite-icon__container">
          <motion.div
            whileHover={{ scale: 1.35 }}
            layoutId={`favorite-container${candidate_profile_status_id}`}
          >
            <HeartIcon
              onClick={onFavorite}
              className={`action-icon heart-icon state-transition pointer${
                is_favourite ? " selected" : ""
              }`}
            />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

CandidatePreviewCard.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onFavorite: PropTypes.func.isRequired,
};

export default CandidatePreviewCard;
