import "./NewMatchesList.css";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AnimatePresence, motion, useAnimation } from "framer-motion";

import NewMatchesCard from "../NewMatchesCard/NewMatchesCard";
import { ReactComponent as LikeIcon } from "../../../assets/images/big-like-icon.svg";
import { ReactComponent as DislikeIcon } from "../../../assets/images/big-dislike-icon.svg";
import { setNewMatchesAvailable, setShowNewMatches } from "../../../store/slices/Candidate";
import {
  useGetNewMatchCandidatesQuery,
  useVoteDownCandidateQuery,
  useVoteUpCandidateQuery,
} from "./../../../queries/NewMatches";
import { useLocation } from "react-router-dom";

export const NewMatchesList = () => {
  const dislikeControls = useAnimation();
  const likeControls = useAnimation();
  const { data: candidates } = useGetNewMatchCandidatesQuery();
  const { mutate: voteUp } = useVoteUpCandidateQuery();
  const { mutate: voteDown } = useVoteDownCandidateQuery();
  const [index, setIndex] = useState(0);
  const [exitX, setExitX] = useState("100%");
  const [exitY, setExitY] = useState(0);
  const [isFromEmail, setisFromEmail] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  const onBack = useCallback(() => dispatch(setShowNewMatches(false)), [dispatch]);

  const initialIconAnimation = (controls) => {
    controls.start({
      rotate: [0, 40, -40, 40, -40, 0],
      transition: {
        delay: 2,
        duration: 1.5,
        ease: "easeInOut",
        times: [0, 0.15, 0.3, 0.45, 0.75, 1],
        repeat: Infinity,
        repeatDelay: 4,
      },
    });
  };

  const scaleIconAnimation = (controls) => {
    controls.stop();
    controls.start({ rotate: 0, scale: 2 });
  };

  const resetIconAnimation = async (controls) => {
    await controls.start({
      scale: 1,
    });
    initialIconAnimation(controls);
  };

  const handleDislike = () => {
    setIndex(index + 1);
    voteDown(candidates[0]);
  };

  const handleLike = () => {
    setIndex(index + 1);
    voteUp(candidates[0]);
  };

  const onBackHandler = () => {
    window.location = "/";
  }

  useEffect(() => {
    if (candidates?.length === 0) {
      setTimeout(() => {
        onBack();
        dispatch(setNewMatchesAvailable(false));
      }, 400);
    } 
    if(location.pathname.includes("candidate-profile-cards")){
      setisFromEmail(true)
    }
  }, [candidates, dispatch, onBack, location]);

  return (
    <motion.div className="new-matches-list">
      {isFromEmail ?
      <div className="back-button state-transition pointer" onClick={onBackHandler} />
      :
      <div className="back-button state-transition pointer" onClick={onBack} />
    }
      {/* <div className="back-button state-transition pointer" onClick={onBack} /> */}
      <div className="angle-background">
        <svg preserveAspectRatio="none" viewBox="0 0 100 100">
          <polygon fill="#133b58" points="100,100 0,0 0,100" opacity="1"></polygon>
        </svg>
      </div>
      <AnimatePresence>
        {candidates?.length && (
          <motion.div
            className="dislike-icon pointer"
            initial={() => initialIconAnimation(dislikeControls)}
            animate={dislikeControls}
            exit={{ opacity: 0 }}
            onClick={handleDislike}
            onMouseEnter={() => {
              scaleIconAnimation(dislikeControls);
              if (exitX !== -2000) setExitX(-2000);
              if (exitY !== 0) setExitY(0);
            }}
            onMouseLeave={() => resetIconAnimation(dislikeControls)}
          >
            <DislikeIcon />
          </motion.div>
        )}
      </AnimatePresence>
      <div className="cards">
        <div className="cards-container">
          <AnimatePresence initial={false}>
            {candidates?.length >= 6 && (
              <NewMatchesCard
                key={index + 5}
                initial={{ scale: 0, y: 200 }}
                animate={{ scale: 0.9, y: 40 }}
                transition={{
                  y: { duration: 0.5 },
                  scale: { duration: 0.5 },
                }}
                data={candidates[5]}
              />
            )}
            {candidates?.length >= 5 && (
              <NewMatchesCard
                key={index + 4}
                initial={{ scale: 0, y: 200 }}
                animate={{ scale: 0.9, y: 40 }}
                transition={{
                  y: { duration: 0.5 },
                  scale: { duration: 0.5 },
                }}
                data={candidates[4]}
              />
            )}
            {candidates?.length >= 4 && (
              <NewMatchesCard
                key={index + 3}
                initial={{ scale: 0, y: 200 }}
                animate={{ scale: 0.9, y: 40 }}
                transition={{
                  y: { duration: 0.5 },
                  scale: { duration: 0.5 },
                }}
                data={candidates[3]}
              />
            )}
            {candidates?.length >= 3 && (
              <NewMatchesCard
                key={index + 2}
                initial={{ scale: 0, y: 200 }}
                animate={{ scale: 0.9, y: 40 }}
                transition={{
                  y: { duration: 0.5 },
                  scale: { duration: 0.5 },
                }}
                data={candidates[2]}
              />
            )}
            {candidates?.length >= 2 && (
              <NewMatchesCard
                key={index + 1}
                initial={{ scale: 0, y: 100 }}
                animate={{ scale: 0.95, y: 20 }}
                transition={{
                  y: { duration: 0.5 },
                  scale: { duration: 0.5 },
                }}
                data={candidates[1]}
              />
            )}
            {candidates?.length >= 1 && (
              <NewMatchesCard
                key={index}
                animate={{
                  scale: 1,
                  y: 0,
                  opacity: 1,
                }}
                transition={{
                  y: { duration: 0.5 },
                  opacity: { duration: 0.2 },
                }}
                exitX={exitX}
                setExitX={setExitX}
                exitY={exitY}
                setExitY={setExitY}
                index={index}
                setIndex={setIndex}
                isLast={candidates?.length === 1}
                drag={true}
                data={candidates[0]}
                onDislike={voteDown}
                onLike={voteUp}
              />
            )}
          </AnimatePresence>
        </div>
      </div>
      <AnimatePresence>
        {candidates?.length && (
          <motion.div
            className="like-icon pointer"
            initial={() => initialIconAnimation(likeControls)}
            animate={likeControls}
            exit={{ opacity: 0 }}
            onClick={handleLike}
            onMouseEnter={() => {
              scaleIconAnimation(likeControls);
              if (exitX !== 2000) setExitX(2000);
              if (exitY !== 0) setExitY(0);
            }}
            onMouseLeave={() => resetIconAnimation(likeControls)}
          >
            <LikeIcon />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default NewMatchesList;
