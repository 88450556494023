import request from "../utils/request";

const RECRUITER_API_URL = "recruiter/api";

const StatsService = {
  getMyDashboardData: () => {
    return request({
      url: `${RECRUITER_API_URL}/getMyDashboardData`,
      method: "get",
    });
  },
};

export default StatsService;
