import CryptoJS from "crypto-js";
import config from "../config";

export const encryptFunc = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), config.api.encryptKey).toString();
};

export const decryptFunc = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, config.api.encryptKey);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};
