import PropTypes from "prop-types";
import { dateFormatOptions, dateTimeLocale, timeFormatOptions } from "../../../utils/dateTime";
import "./UpcomingInterviewListItem.css";

const UpcomingInterviewListItem = ({ data }) => {
  const { firstName, lastName, date, description, link } = data || {};
  const dateObj = new Date(date);

  return (
    <div className="upcoming-interview-list-item">
      <div className="upcoming-interview-list-item__header">
        <span className="upcoming-interview-list-item__name">
          {firstName}
          <br />
          {lastName}
        </span>
        <span className="upcoming-interview-list-item__date">
          {dateObj.toLocaleDateString(dateTimeLocale, dateFormatOptions)}
          <br />
          {dateObj.toLocaleTimeString(dateTimeLocale, timeFormatOptions)}
        </span>
      </div>
      <p className="upcoming-interview-list-item__description">{description}</p>
      <a href={link} className="upcoming-interview-list-item__link">
        {link}
      </a>
    </div>
  );
};

UpcomingInterviewListItem.propTypes = {
  data: PropTypes.object.isRequired,
};

export default UpcomingInterviewListItem;
