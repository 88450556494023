import "./CandidateListItem.css";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

import { ReactComponent as HeartIcon } from "../../../assets/images/heart-icon.svg";
import { ReactComponent as GithubIcon } from "../../../assets/images/github-icon.svg";
import { ReactComponent as LinkedinIcon } from "../../../assets/images/linkedin-icon.svg";
import { ReactComponent as PortfolioIcon } from "../../../assets/images/portfolio-icon.svg";
import { ReactComponent as ChevronIcon } from "../../../assets/images/chevron-icon.svg";
import { renderCandidateStatusIcon, linkSocialIcon, constructLocationString } from "../helper";
import Avatar from "../../shared/Avatar/Avatar";

const CandidateListItem = ({ data, onClick, onFavorite }) => {
  const {
    candidate_profile_status_id,
    first_name,
    last_name,
    tech_stack,
    city,
    state,
    country,
    hired_datetime,
    profile_status,
    experience = 0,
    avatar = undefined,
    assessment_score = 0,
    github,
    linkedin,
    portfolio,
    is_favourite,
  } = data || {};

  return (
    <motion.div
      layoutId={`card-${candidate_profile_status_id}`}
      className="candidate-list-item pointer no-tap-highlight"
      onClick={onClick}
    >
      <div className="d-flex justify-content-between">
        <motion.span layoutId={`experience${candidate_profile_status_id}`} className="experience">
          {experience + (experience === 1 ? " YR " : " YRS ") + "Experience"}
        </motion.span>
        <div className="status-icon__container">
          <motion.div layoutId={`status${candidate_profile_status_id}`}>
            {renderCandidateStatusIcon(profile_status)}
          </motion.div>
        </div>
      </div>
      <div className="d-flex justify-content-between personal-info-row">
        <div className="photo-info-container">
          <motion.div
            layoutId={`photo-container${candidate_profile_status_id}`}
            className="photo-container"
          >
            {assessment_score && <ChevronIcon className="chevron" />}
            <Avatar
              src={avatar}
              layoutId={`avatar${candidate_profile_status_id}`}
              alt={`${first_name}'s Profile`}
              width={100}
              height={100}
            />
          </motion.div>
        </div>
        <div className="personal-info d-flex flex-column">
          <motion.span
            layoutId={`first-name${candidate_profile_status_id}`}
            className="first-name d-inline-block align-self-start"
          >
            {first_name}
          </motion.span>
          <motion.span
            layoutId={`last-name${candidate_profile_status_id}`}
            className="last-name  d-inline-block align-self-start"
          >
            {last_name}
          </motion.span>
          <motion.span
            layoutId={`title-name${candidate_profile_status_id}`}
            className="title  d-inline-block align-self-start"
          >
            {tech_stack}
          </motion.span>
          <motion.span
            layoutId={`location-name${candidate_profile_status_id}`}
            className="location  d-inline-block align-self-start"
          >
            {constructLocationString(city, state, country)}
          </motion.span>
        </div>
      </div>
      <div className="d-flex justify-content-between hired-date-row">
        {
          <motion.span layoutId={`hired${candidate_profile_status_id}`} className="hired-date">
            {hired_datetime && `Hired ${new Date(hired_datetime).toLocaleDateString()}`}
          </motion.span>
        }
        <motion.div
          layoutId={`social-icons${candidate_profile_status_id}`}
          className="social-icons"
        >
          {linkSocialIcon(github, GithubIcon)}
          {linkSocialIcon(linkedin, LinkedinIcon)}
          {linkSocialIcon(portfolio, PortfolioIcon)}
        </motion.div>
        <div className="favorite-icon__container">
          <motion.div
            whileHover={{ scale: 1.35 }}
            whileTap={{ scale: 1.35 }}
            layoutId={`favorite-container${candidate_profile_status_id}`}
          >
            <HeartIcon
              onClick={onFavorite}
              className={`action-icon heart-icon state-transition${
                is_favourite ? " selected" : ""
              }`}
            />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

CandidateListItem.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onFavorite: PropTypes.func.isRequired,
};

export default CandidateListItem;
