import PropTypes from "prop-types";
import "./CandidatesStatsCardListItem.css";

const CandidatesStatsCardListItem = ({ indicatorColorVar, title, figure = "N/A" }) => {
  const indicatorStyle = {
    backgroundColor: `var(${indicatorColorVar})`,
  };

  return (
    <div className="candidates-stats-card__container">
      <div className="candidates-stats-card__figure-box">
        <span className="candidates-stats-card__figure">{figure}</span>
      </div>
      <div className="indicator" style={indicatorStyle} />
      <span className="candidates-stats-card__title">{title}</span>
    </div>
  );
};

CandidatesStatsCardListItem.propTypes = {
  indicatorColorVar: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  figure: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CandidatesStatsCardListItem;
