import { useMutation, useQuery, useQueryClient } from "react-query";
import queryStore from ".";
import InterviewService from "./../services/Interview";

export const INTERVIEW_QUERY_KEY = "interview";

queryStore.setQueryDefaults(INTERVIEW_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 0,
});

export const useGetInterviewsQuery = (queryOptions) =>
  useQuery(
    INTERVIEW_QUERY_KEY,
    async () => {
      const res = await InterviewService.getInterviews();
      console.log(res)  
      return res;
    },
    queryOptions
  );

