import { createSlice } from "@reduxjs/toolkit";
import { getItem } from "../../utils/localStorage";
import { HOME_TABS } from "../../utils/misc";

const initialState = {
  token: getItem("token") || null,
  user: null,
  waitingResponseCount: 0,
  activeMobileTab: HOME_TABS.campaings,
  ai: {
    isActive: true,
    isModalVisible: false,
  },
};

const AppSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setToken(state, { payload }) {
      state.token = payload;
    },
    setUser(state, { payload }) {
      state.user = payload;
    },
    incrementWaitingResponseCount(state) {
      state.waitingResponseCount += 1;
    },
    decrementWaitingResponseCount(state) {
      if (state.waitingResponseCount) {
        state.waitingResponseCount -= 1;
      }
    },
    setActiveMobileTab(state, { payload }) {
      state.activeMobileTab = payload;
    },
    toggleIsAIActive(state) {
      state.ai.isActive = !state.ai.isActive;
    },
    setIsAIModalVisible(state, { payload }) {
      state.ai.isModalVisible = payload;
    },
  },
});

export const {
  setToken,
  setUser,
  incrementWaitingResponseCount,
  decrementWaitingResponseCount,
  setActiveMobileTab,
  toggleIsAIActive,
  setIsAIModalVisible,
} = AppSlice.actions;
export default AppSlice.reducer;
