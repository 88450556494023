// ids (both object keys and property id) must be unique
export const questions = {
  partOne: {
    q0: {
      title: "What type of software engineer are you currently hiring for? (check all that apply)",
      options: {
        q0a0: { id: "q0a0", title: "Front-end Developer", checked: false },
        q0a1: { id: "q0a1", title: "Back-end Developer", checked: false },
        q0a2: { id: "q0a2", title: "Cyber Security Engineer", checked: false },
        q0a3: { id: "q0a3", title: "Salesforce Developer", checked: false },
        q0a4: { id: "q0a4", title: "CTO as a Service", checked: false },
        q0a5: { id: "q0a5", title: "Other", value: "", checked: false, isCustom: true },
        q0a6: { id: "q0a6", title: "I don’t know", checked: false },
      },
      id: "q0",
      index: "1",
      type: "checkbox",
    },
    q1: {
      title: "Which of the following communication styles do you prefer for members of your team?",
      options: {
        q1a0: { id: "q1a0", title: "Reserved", checked: false },
        q1a1: { id: "q1a1", title: "Direct", checked: false },
        q1a2: { id: "q1a2", title: "No preference", checked: false },
      },
      id: "q1",
      index: "2",
      type: "radio",
    },
    q2: {
      title: "What type of team member best describes one that aligns with your company culture?",
      options: {
        q2a0: { id: "q2a0", title: "Enjoys structure and rules", checked: false },
        q2a1: { id: "q2a1", title: "Flexible and adaptable", checked: false },
        q2a2: { id: "q2a2", title: "Other", value: "", checked: false, isCustom: true },
        q2a3: { id: "q2a3", title: "No preference", checked: false },
      },
      id: "q2",
      index: "3",
      type: "radio",
    },
    q3: {
      title: "What type of team member demeanor aligns best with your company culture?",
      options: {
        q3a0: { id: "q3a0", title: "Relaxed", checked: false },
        q3a1: { id: "q3a1", title: "Intense", checked: false },
        q3a2: { id: "q3a2", title: "Other", value: "", checked: false, isCustom: true },
        q3a3: { id: "q3a3", title: "No preference", checked: false },
      },
      id: "q3",
      index: "4",
      type: "checkbox",
    },
    q4: {
      title: "Are you interested in remote software engineers or on site software engineers only?",
      options: {
        q4a0: {
          id: "q4a0",
          title: "I am ok with team members working remote 100% of the time",
          checked: false,
        },
        q4a1: {
          id: "q4a1",
          title: "I am ok with team members working remote on occasion",
          checked: false,
        },
        q4a2: {
          id: "q4a2",
          title: "All work must be performed on site",
          checked: false,
        },
        q4a3: {
          id: "q4a3",
          title: "No preference",
          checked: false,
        },
      },
      id: "q4",
      index: "5",
      type: "checkbox",
    },
  },
  partTwo: {
    q5: {
      title: "How would you describe your current team’s daily attitude towards work?",
      options: {
        q5a0: {
          id: "q5a0",
          title: "Optimistic",
          checked: false,
        },
        q5a1: {
          id: "q5a1",
          title: "Skeptical",
          checked: false,
        },
        q5a2: {
          id: "q5a2",
          title: "Other",
          value: "",
          checked: false,
          isCustom: true,
        },
        q5a3: {
          id: "q5a3",
          title: "No preference",
          checked: false,
        },
      },
      id: "q5",
      index: "6",
      type: "radio",
    },
    q6: {
      title: "How important is collaboration to your existing team?",
      options: {
        q6a0: {
          id: "q6a0",
          title: "Very important",
          checked: false,
        },
        q6a1: {
          id: "q6a1",
          title: "Important",
          checked: false,
        },
        q6a2: {
          id: "q6a2",
          title: "Unimportant",
          checked: false,
        },
        q6a3: {
          id: "q6a3",
          title: "No preference",
          checked: false,
        },
      },
      id: "q6",
      index: "7",
      type: "radio",
    },
    q7: {
      title: "How do you prefer team members approach conflict resolution?",
      options: {
        q7a0: {
          id: "q7a0",
          title: "Approach and communicate the problem directly with leadership",
          checked: false,
        },
        q7a1: {
          id: "q7a1",
          title: "Solve the conflict on their own",
          checked: false,
        },
        q7a2: {
          id: "q7a2",
          title: "No preference",
          checked: false,
        },
      },
      id: "q7",
      index: "8",
      type: "checkbox",
    },
    q8: {
      title: "How many software engineers are you currently hiring to augment your existing team?",
      options: {
        q8a0: {
          id: "q8a0",
          title: "1",
          checked: false,
        },
        q8a1: {
          id: "q8a1",
          title: "1-3",
          checked: false,
        },
        q8a2: {
          id: "q8a2",
          title: "3-5",
          checked: false,
        },
        q8a3: {
          id: "q8a3",
          title: "5+",
          checked: false,
        },
      },
      id: "q8",
      index: "9",
      type: "radio",
    },
    q9: {
      title: "Do you prefer your team members to be people oriented, task oriented or both?",
      options: {
        q9a0: {
          id: "q9a0",
          title: "People oriented",
          checked: false,
        },
        q9a1: {
          id: "q9a1",
          title: "Task oriented",
          checked: false,
        },
        q9a2: {
          id: "q9a2",
          title: "Both",
          checked: false,
        },
        q9a3: {
          id: "q9a3",
          title: "Other",
          value: "",
          checked: false,
          isCustom: true,
        },
        q9a4: {
          id: "q9a4",
          title: "No preference",
          checked: false,
        },
      },
      id: "q9",
      index: "10",
      type: "radio",
    },
  },
};

export const prepareQuestionnaireForAPI = (data) => {
  const questionnaire = [];

  Object.values(data).forEach((question, qeustionIndex) => {
    questionnaire[qeustionIndex] = { question: question.title, answer: "" };

    if (question.type === "checkbox") {
      Object.values(question.options).forEach((option, optionIndex) => {
        if (option.checked) {
          if (optionIndex === 0) {
            questionnaire[qeustionIndex].answer = option.isCustom ? option.value : option.title;
          } else {
            questionnaire[qeustionIndex].answer += !!questionnaire[qeustionIndex].answer
              ? `, ${option.isCustom ? option.value : option.title}`
              : option.isCustom
              ? option.value
              : option.title;
          }
        }
      });
    } else {
      for (const [, option] of Object.entries(question.options)) {
        if (option.checked) {
          questionnaire[qeustionIndex].answer = option.isCustom ? option.value : option.title;
          break;
        }
      }
    }
  });

  return questionnaire;
};
