import "./AccountDetailsForm.css";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { accountSettingsValidationRules } from "../../../../validation/account";
import StandardInputField from "../../../shared/inputs/StandardInputField/StandardInputField";
import Button from "../../../shared/Button/Button";
import { ReactComponent as UserPhotoIcon } from "../../../../assets/images/user-photo-icon.svg";
import { ReactComponent as UploadIcon } from "../../../../assets/images/upload-icon.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/images/trash-icon.svg";
import { IfMobile, IfDesktop, useIfMobile } from "../../../../utils/responsive";
import { logoutRequest } from "../../../../store/slices/Auth";
import { useUpdateUserQuery } from "../../../../queries/User";

const AccountDetailsForm = ({ data }) => {
  const { mutate: updateProfile } = useUpdateUserQuery();
  const isMobile = useIfMobile();
  const dispatch = useDispatch();

  const handleLogout = () => dispatch(logoutRequest());

  const handleChoosePhoto = (event, setFieldValue) =>
    event.currentTarget.files[0] && setFieldValue("avatar", event.currentTarget.files[0]);

  const handleRemovePhoto = (event, setFieldValue) => {
    event.preventDefault();
    setFieldValue("avatar", null);
  };

  const handleClearFileChooser = (event) => (event.target.value = null);

  return (
    <>
      <IfDesktop>
        <div className="account__logout">
          <Button
            type="button"
            backgroundColorVar="--color-raspberry-glaze"
            title="LOGOUT"
            width={124}
            onClick={handleLogout}
          />
        </div>
      </IfDesktop>
      <Formik
        initialValues={data}
        onSubmit={updateProfile}
        validationSchema={accountSettingsValidationRules}
        enableReinitialize
      >
        {({ values, setFieldValue }) => (
          <Form className="account-details-form" noValidate>
            <div className="account-details-form__user-info">
              <div className="first-row">
                <div className="profile-photo__column">
                  <div className="profile-photo__container">
                    {!!values?.avatar ? (
                      <>
                        <label htmlFor="upload-user-photo-field" className="pointer">
                          <img
                            src={
                              values?.avatar?.name
                                ? URL.createObjectURL(values.avatar)
                                : values.avatar
                            }
                            alt="User Avatar"
                            className="user-photo__container"
                          />
                        </label>
                        <label
                          htmlFor="upload-user-photo-field"
                          className="user-photo__action-icon-container pointer no-tap-highlight"
                        >
                          <TrashIcon
                            className="remove-user-photo-icon clickable state-transition"
                            onClick={(event) => handleRemovePhoto(event, setFieldValue)}
                          />
                        </label>
                      </>
                    ) : (
                      <>
                        <label className="user-photo__container" htmlFor="upload-user-photo-field">
                          <UserPhotoIcon />
                        </label>
                        <label
                          className="user-photo__action-icon-container pointer no-tap-highlight"
                          htmlFor="upload-user-photo-field"
                        >
                          <UploadIcon className="upload-user-photo-icon clickable state-transition" />
                        </label>
                      </>
                    )}
                    <input
                      id="upload-user-photo-field"
                      type="file"
                      name="avatar"
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={(event) => handleChoosePhoto(event, setFieldValue)}
                      onClick={handleClearFileChooser}
                    />
                  </div>
                  <IfMobile>
                    <Button
                      type="button"
                      backgroundColorVar="--color-raspberry-glaze"
                      title="LOGOUT"
                      onClick={handleLogout}
                    />
                  </IfMobile>
                </div>
                <div className="form-fields_column">
                  <Field
                    label="First Name"
                    type="text"
                    name="first_name"
                    component={StandardInputField}
                  />
                  <Field
                    label="Last Name"
                    type="text"
                    name="last_name"
                    component={StandardInputField}
                  />
                  <Field
                    label="Phone"
                    type="text"
                    name="phone_number"
                    component={StandardInputField}
                  />
                  <Field
                    label="Email"
                    type="email"
                    name="email_id"
                    component={StandardInputField}
                  />
                </div>
                <div className="form-fields_column">
                  <Field
                    label="Job Title"
                    type="text"
                    name="job_title"
                    component={StandardInputField}
                  />
                  <Field
                    label="Company Name"
                    type="text"
                    name="company_name"
                    component={StandardInputField}
                  />
                  <Field
                    label="Company URL"
                    type="url"
                    name="company_url"
                    autoCapitalize="off"
                    component={StandardInputField}
                  />
                </div>
              </div>
              <div className="second-row">
                <Button
                  type="submit"
                  title="UPDATE"
                  width={168}
                  margin={isMobile ? "24px 0 0 0" : "38px 0 0 0"}
                />
              </div>
            </div>
            <IfDesktop>
              <hr />
            </IfDesktop>
          </Form>
        )}
      </Formik>
    </>
  );
};

AccountDetailsForm.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AccountDetailsForm;
