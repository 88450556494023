import { ReactComponent as BriefcaseIcon } from "../../assets/images/briefcase-icon.svg";
import { ReactComponent as LikeIcon } from "../../assets/images/like-icon.svg";
import { ReactComponent as CheckCircleIcon } from "../../assets/images/check-circle-icon.svg";
import config from "./../../config";

export const renderCandidateStatusIcon = (status) => {
  switch (status) {
    case config.candidate.profileStatus.VOTED_UP:
      return <LikeIcon className={"action-icon"} />;
    case config.candidate.profileStatus.INTERVIEWED:
      return <CheckCircleIcon className={"action-icon"} />;
    case config.candidate.profileStatus.HIRED:
      return <BriefcaseIcon className={"action-icon briefcase-icon"} />;
    default:
      return null;
  }
};

export const linkSocialIcon = (url, SocialIcon) => {
  const renderIcon = <SocialIcon fill={`var(${url ? "--color-brig" : "--color-big-sur"})`} />;

  return !!url ? (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer nofollow"
      onClick={(e) => e.stopPropagation()}
    >
      {renderIcon}
    </a>
  ) : (
    renderIcon
  );
};

export const constructLocationString = (city, state, country) =>
  [city, state, country].filter(Boolean).join(", ");
