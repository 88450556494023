import "./EmptyCandidateListItem.css";
import { motion } from "framer-motion";
import { ReactComponent as CandidateAvatarIcon } from "../../../assets/images/candidate-avatar-icon.svg";

const EmptyCandidateListItem = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="empty-candidate-list-item"
    >
      <span className="title">
        This place awaits another
        <br />
        perfectly vetted employee
      </span>
      <CandidateAvatarIcon className="user-icon" />
    </motion.div>
  );
};

export default EmptyCandidateListItem;
