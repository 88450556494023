import "./ChangePasswordForm.css";
import { Field, Form, Formik } from "formik";
import PropTypes from "prop-types";
import { changePasswordValidationRules } from "../../../../validation/account";
import StandardInputField from "../../../shared/inputs/StandardInputField/StandardInputField";
import Button from "../../../shared/Button/Button";
import { useIfMobile } from "../../../../utils/responsive";

const ChangePasswordForm = ({ onSubmit, username }) => {
  const isMobile = useIfMobile();

  return (
    <Formik
      initialValues={{
        password: "",
        repeatPassword: "",
      }}
      onSubmit={onSubmit}
      validationSchema={changePasswordValidationRules}
    >
      {() => (
        <Form className="change-password-form d-flex justify-content-center">
          <div className="column d-flex flex-column">
            <Field type="text" name="username" autoComplete="username" value={username} hidden />
            <Field
              label="New Password"
              type="password"
              name="password"
              component={StandardInputField}
              autoComplete="new-password"
            />
            <Field
              label="Repeat Password"
              type="password"
              name="repeatPassword"
              component={StandardInputField}
              autoComplete="new-password"
            />
            <Button
              type="submit"
              title="CHANGE PASSWORD"
              width={168}
              margin={isMobile ? "24px 0 0 0" : "38px 0 0 0"}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

ChangePasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};

export default ChangePasswordForm;
