import { Flip } from "react-toastify";

const config = {
  api: {
    baseUrl: "https://dev-api.trusteddev.ai", //process.env.REACT_APP_API_URL,
    encryptKey: "trusted_dev_encrypt_key", //process.env.REACT_APP_ENCRYPT_KEY,
  },
  candidate: {
    profileStatus: {
      UNMATCHED: 0,
      MATCHED: 1,
      VOTED_UP: 2,
      VOTED_DOWN: 3,
      INTERVIEWED: 4,
      HIRED: 6,
      REJECTED: 7,
    },
  },
  common: {
    tdWebsiteUrl: "https://dcg.dev/", //process.env.REACT_APP_TD_WEBSITE_URL,
  },
  query: {
    staleTime: 10000, // 10s
    cacheTime: 86400000, // 24h
  },
  toast: {
    position: "bottom-right",
    autoClose: 4000,
    newestOnTop: true,
    closeOnClick: false,
    rtl: false,
    pauseOnFocusLoss: true,
    draggable: true,
    pauseOnHover: true,
    transition: Flip,
  },
  calendly: {
    url: "https://calendly.com/ryandev",
  },
};

export default config;
