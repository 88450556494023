import "./GlobalLoader.css";
import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { selectIsAppLoading } from "../../../store/selectors/App";

const animateLoaderOptions = {
  animate: {
    width: ["0%", "10%", "50%", "80%", "85%"],
    transition: { times: [0, 0.2, 0.7, 0.95, 1], duration: 8 },
  },
  exit: { opacity: 0, width: "100%", transition: { duration: 0.3 } },
  transition: { ease: "easeInOut" },
};

const GlobalLoader = () => {
  const isAppLoading = useSelector(selectIsAppLoading);

  return (
    <AnimatePresence exitBeforeEnter>
      {isAppLoading && <motion.div {...animateLoaderOptions} className="global-loader" />}
    </AnimatePresence>
  );
};

export default GlobalLoader;
