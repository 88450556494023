import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "../store/selectors/App";
import { routes } from "./routes";

export const PublicRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Redirect to={routes.home} /> : <Component {...props} />
      }
    />
  );
};

export default PublicRoute;
