import { useMutation, useQuery, useQueryClient } from "react-query";
import queryStore from ".";
import UserService from "../services/User";
import { store } from "../store";
import { setUser } from "../store/slices/App";
import { logSuccess } from "../utils/logging";
import { isFile } from "../utils/misc";

export const USER_QUERY_KEY = "user";

queryStore.setQueryDefaults(USER_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  placeholderData: {
    avatar: undefined,
    email_id: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    job_title: "",
    company_name: "",
    company_url: "",
  },
});

export const useGetUserQuery = (queryOptions) =>
  useQuery(
    USER_QUERY_KEY,
    async () => {
      const user = await UserService.getUser();
      store.dispatch(setUser(user));

      return user;
    },
    queryOptions
  );

export const useUpdateUserQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const avatar = data?.avatar;
      const avatarAvailableForUpload = isFile(avatar);
      const avatarWasSet = !!store.getState()?.AppSlice?.user?.avatar;
      const avatarIsCurrentlyNotSet =
        avatar === null || avatar === "" || (!avatarWasSet && !avatarAvailableForUpload);
      const avatarIsUpdated = avatarWasSet && avatarAvailableForUpload;
      const avatarWasNotSetAndIsNowSet = !avatarWasSet && avatarAvailableForUpload;

      // deleting old avatar
      if (avatarWasSet && (avatarIsCurrentlyNotSet || avatarIsUpdated)) {
        await UserService.removeUserProfilePicture();
      }

      // uploading new avatar
      if ((avatarIsUpdated && !avatarIsCurrentlyNotSet) || avatarWasNotSetAndIsNowSet) {
        const { file_name } = await UserService.uploadFile(avatar);
        const splittedFileName = file_name.split("/");

        var fileName = splittedFileName[splittedFileName.length - 1];
      }

      if (avatarAvailableForUpload) {
        // update user profile and avatar
        await UserService.updateUser(data, fileName);
      } else {
        // update user profile
        await UserService.updateUser(data);
      }
    },
    {
      onSuccess: () => {
        logSuccess("Changes Successfully Saved");
      },
      onSettled: () => {
        queryClient.invalidateQueries(USER_QUERY_KEY);
      },
    }
  );
};
