import "./AIModal.css";
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button/Button";
import { selectIsAIModalVisible } from "./../../../store/selectors/App";
import { setIsAIModalVisible } from "../../../store/slices/App";
import { aiModalBackdropVariants } from "../../../utils/animation";
import { aiModalContentVariants } from "./../../../utils/animation";

const AIModal = () => {
  const isVisible = useSelector(selectIsAIModalVisible);
  const dispatch = useDispatch();

  const closeModal = () => dispatch(setIsAIModalVisible(false));

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          variants={aiModalBackdropVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          className="ai-modal"
        >
          <motion.div
            variants={aiModalContentVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            className="d-flex flex-column justify-content-center text-center content"
          >
            <h1>AI OFF</h1>
            <p>You have turned off the machine learning algorithm for your account</p>
            <Button className="align-self-center" title="OK" onClick={closeModal} />
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AIModal;
