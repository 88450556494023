import "./Account.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccountDetailsForm from "../../components/forms/account/AccountDetails/AccountDetailsForm";
import ChangePasswordForm from "./../../components/forms/account/ChangePassword/ChangePasswordForm";
import TwoStepVerificationForm from "../../components/forms/account/TwoStepVerification/TwoStepVerificationForm";
import { useGetUserQuery } from "../../queries/User";
import {
  resetPasswordOTPRequest,
  changePasswordRequest,
  setIsChangePassword,
} from "../../store/slices/Auth";
import { selectIsChangePassword } from "../../store/selectors/Auth";

const Account = () => {
  const [newPassword, setNewPassword] = useState(null);
  const isChangePassword = useSelector(selectIsChangePassword);
  const { isFetched, data } = useGetUserQuery({ staleTime: 0 });
  const dispatch = useDispatch();

  const savePasswordAndProceed = ({ password }) => {
    sendOTP();
    setNewPassword(password);
    dispatch(setIsChangePassword(false));
  };

  const verifyOTPAndSetNewPassword = ({ code }) =>
    dispatch(
      changePasswordRequest({
        reset_password_otp: code,
        phone_number: data?.phone_number,
        password: newPassword,
      })
    );

  const sendOTP = () => dispatch(resetPasswordOTPRequest({ phone_number: data?.phone_number }));

  return (
    <div className="account">
      {isFetched && (
        <>
          <AccountDetailsForm data={data} />
          <div className="change-password d-flex flex-column justify-content-center flex-grow-1">
            {!isChangePassword ? (
              <div className="align-self-center">
                <TwoStepVerificationForm
                  submitLabel="CONFIRM"
                  phone={data?.phone_number}
                  onSubmit={verifyOTPAndSetNewPassword}
                  onResend={sendOTP}
                  submitBackgroundColorVar="--color-brig"
                />
              </div>
            ) : (
              <ChangePasswordForm onSubmit={savePasswordAndProceed} username={data?.email_id} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Account;
