import * as Yup from "yup";
import { regex } from "../utils/regex";

export const loginValidationRules = Yup.object().shape({
  email_id: Yup.string().required("Email is required").email("Invalid email format"),
  password: Yup.string().required("Password is required"),
});

export const forgotPasswordRules = Yup.object().shape({
  phone_number: Yup.string().required("Mobile number is required"),
});

export const createNewPasswordRules = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password should be eight (8) characters in length")
    .matches(regex.password, "Password doesn't follow the second requirement described below"),
  confirm_password: Yup.string()
    .required("Confirm Password is required")
    .min(8, "Password should be eight (8) characters in length")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});
