import "./NewMatchesCard.css";
import { motion, useMotionValue, useTransform } from "framer-motion";

import Avatar from "./../../shared/Avatar/Avatar";
import { ReactComponent as ChevronIcon } from "../../../assets/images/chevron-icon.svg";
import { ReactComponent as ViewResumeIcon } from "../../../assets/images/view-resume-icon.svg";
import { ReactComponent as StarIcon } from "../../../assets/images/star-icon.svg";
import { constructLocationString } from "../../candidates/helper";
import Button from "./../../shared/Button/Button";

const NewMatchesCard = ({
  data,
  initial,
  animate,
  drag,
  transition,
  setExitX,
  setExitY,
  setIndex,
  exitX,
  exitY,
  index,
  isLast,
  onDislike,
  onLike,
}) => {
  const x = useMotionValue(0);
  const rotateX = useTransform(x, [-300, 0, 300], [-5, 0, 5], {
    clamp: false,
  });

  function handleDragEnd(_, info) {
    setExitY(info.offset.y);

    if (info.offset.x < -100) {
      setExitX(-2000);
      if (!isLast) {
        setIndex(index + 1);
      }
      onDislike(data);
    }
    if (info.offset.x > 100) {
      setExitX(2000);
      if (!isLast) {
        setIndex(index + 1);
      }
      onLike(data);
    }
  }

  return (
    <motion.div
      className="new-matches-card"
      style={{
        width: 300,
        height: 440,
        position: "absolute",
        x: x,
        rotate: rotateX,
        cursor: "grab",
      }}
      whileTap={{ cursor: "grabbing" }}
      drag={drag}
      dragConstraints={{ top: 0, right: 0, bottom: 0, left: 0 }}
      onDragEnd={handleDragEnd}
      initial={initial}
      animate={animate}
      transition={transition}
      exit={{
        x: exitX,
        y: exitY,
        opacity: 0,
        transition: { duration: 0.6 },
      }}
      dragTransition={{ bounceStiffness: 600, bounceDamping: 10 }}
    >
      <motion.div
        className="d-flex flex-column"
        style={{
          width: 300,
          height: 440,
          borderRadius: 3,
          backgroundColor: "#F0F5F7",
          boxShadow: `0px 2px 3px 0px rgba(0,0,0,0.16)`,
        }}
      >
        <div className="d-flex align-items-center personal-info-row">
          <div className="photo-container">
            <ChevronIcon className="chevron" />
            <Avatar
              src={data?.avatar}
              alt={`${data?.first_name}'s Profile`}
              width={92}
              height={92}
            />
          </div>
          <div className="personal-info">
            <span className="first-name">{data?.first_name}</span>
            <span className="last-name">{data?.last_name}</span>
            <span className="title">{data?.tech_stack}</span>
            <span className="location">
              {constructLocationString(data?.city, data?.state, data?.country)}
            </span>
          </div>
        </div>
        {!!data?.skills?.length && (
          <div className="skills-row">
            {data?.skills.map(({ id, skill_name }) => (
              <span key={id} className="skill">
                - {skill_name}
              </span>
            ))}
          </div>
        )}
        <div className="education-row flex-grow-1">
          {!!data?.educations?.length && (
            <>
              <span className="degree">{data?.educations[0]?.specialization}</span>
              <span className="institution">
                {data?.educations[0]?.university + ", " + data?.educations[0]?.course}
              </span>
            </>
          )}
          <Button
            className="view-resume-btn align-self-center"
            icon={ViewResumeIcon}
            title="VIEW FULL RESUME"
            onClick={() => {}}
          />
        </div>
        <div className="last-row">
          <div className="titles d-flex align-items-end">
            <span>Culture Fit</span>
            <span>
              Years of
              <br />
              Experience
            </span>
            <span>
              Tech
              <br />
              Assesment
            </span>
          </div>
          <div className="values d-flex">
            <div className="star-icon-group">
              <StarIcon className={`star-icon${data?.culture_fit >= 1 ? " active" : ""}`} />
              <StarIcon className={`star-icon${data?.culture_fit >= 2 ? " active" : ""}`} />
              <StarIcon className={`star-icon${data?.culture_fit >= 3 ? " active" : ""}`} />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <span className="experience">{6 ?? data?.experience}</span>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <ChevronIcon />
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default NewMatchesCard;
