import { MutationCache, QueryCache, QueryClient, setLogger } from "react-query";
import config from "../config";
import { logError, logForDev } from "./../utils/logging";

const queryStore = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: config.query.cacheTime,
      staleTime: config.query.staleTime,
      retry: (failureCount, error) => error?.response?.status >= 500 && failureCount < 2,
    },
    mutations: {
      retry: (failureCount, error) => error?.response?.status >= 500 && failureCount < 2,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => logError(error),
  }),
  mutationCache: new MutationCache({
    onError: (error) => logError(error),
  }),
});

setLogger({
  log: () => logForDev,
  warn: () => logForDev,
  error: () => {},
});

export default queryStore;
