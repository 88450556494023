import { useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import CampaignList from "../../components/campaign/CampaignList/CampaignList";
import UpcomingInterviewList from "../../components/interviews/UpcomingInterviewList/UpcomingInterviewList";
import CandidateList from "../../components/candidates/CandidateList/CandidateList";
import { NewMatchesList } from "../../components/newMatches/NewMatchesList/NewMatchesList";
import StatsSidebar from "../../components/stats/StatsSidebar/StatsSidebar";
import HorizontalGraph from "../../components/stats/HorizontalGraph/HorizontalGraph";
import { newMatchesVariants, sidebarVariants } from "../../utils/animation";
import { centralContentVariants } from "./../../utils/animation";
import { selectShowNewMatches } from "./../../store/selectors/Candidate";

const HomeDesktopLayout = () => {
  const showNewMatches = useSelector(selectShowNewMatches);

  return (
    <div className="home d-flex">
      <AnimatePresence initial={false} exitBeforeEnter>
        {!showNewMatches && (
          <motion.div
            variants={sidebarVariants}
            initial={"hidden"}
            animate={"show"}
            exit={"hidden"}
            className="home__left-sidebar d-flex flex-column"
          >
            <CampaignList />
            <UpcomingInterviewList />
          </motion.div>
        )}
      </AnimatePresence>
      <motion.div className="home__content-wrapper">
        <motion.div className="home__content-container">
          <AnimatePresence initial={false} exitBeforeEnter>
            {!showNewMatches ? (
              <motion.div
                key="candidates"
                variants={centralContentVariants}
                initial={"hidden"}
                animate={"show"}
                exit={"hidden"}
                className="home__content d-flex flex-column flex-grow-1"
              >
                <CandidateList />
              </motion.div>
            ) : (
              <motion.div
                key="new-matches"
                variants={newMatchesVariants}
                initial={"hidden"}
                animate={"show"}
                exit={"hidden"}
                className="new-match"
              >
                <NewMatchesList />
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence initial={false} exitBeforeEnter>
            {!showNewMatches && (
              <motion.div
                variants={sidebarVariants}
                initial={"hidden"}
                animate={"show"}
                exit={"hidden"}
                className="home__right-sidebar"
              >
                <StatsSidebar />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
        <HorizontalGraph containerClassName="home__graph-container" />
      </motion.div>
    </div>
  );
};

export default HomeDesktopLayout;
