import "./CandidatesStatsPieChart.css";
import PropTypes from "prop-types";
import { Bar, BarChart, Cell, LabelList, Pie, PieChart, Tooltip } from "recharts";
import { memo } from "react";

const RADIAN = Math.PI / 180;
const COLORS_CHART = ["#FF7BAC", "#509EC6"];
const COLORS_BAR = ["#5B7E95", "#13B9B7"];

const customTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="candidates-stats__pie-chart-tooltip">
        <span
          style={{ color: "#fff" }}
        >{`${payload[0]?.payload?.name}: ${payload[0]?.value}`}</span>
      </div>
    );
  }

  return null;
};

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    value && (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize={14}
        fontWeight={700}
      >
        {value}
      </text>
    )
  );
};

const renderCustomizedBarLabel = ({ x, y, height, value, index }) => {
  return (
    <g>
      <text
        x={index === 0 ? x - 8 : x + 20}
        y={height < 20 ? y - 5 : y + 10}
        fill={index === 0 ? COLORS_BAR[0] : COLORS_BAR[1]}
        textAnchor={index === 0 ? "end" : "start"}
        dominantBaseline="middle"
        fontSize={14}
        fontWeight={700}
      >
        {value}
      </text>
    </g>
  );
};

const CandidatesStatsPieChart = ({ containerClassName, data = undefined }) => {
  const pieChartData = [
    { name: "Downvoted", value: data?.total_voted_down },
    { name: "Upvoted", value: data?.total_voted_up },
  ];

  const barData = [
    {
      name: "Interviewed",
      value: data?.total_interviewed,
    },
    {
      name: "Hired",
      value: data?.total_hired,
    },
  ];

  return (
    <div
      className={
        "candidates-stats__pie-chart" + (!!containerClassName ? ` ${containerClassName}` : "")
      }
    >
      <PieChart width={160} height={160}>
        <Pie
          data={pieChartData}
          dataKey="value"
          labelLine={false}
          label={renderCustomizedLabel}
          startAngle={90}
          endAngle={450}
          animationBegin={0}
          animationDuration={1000}
        >
          <Cell
            key="cell-0"
            fill={COLORS_CHART[0]}
            style={{ stroke: "#f0f5f7", strokeWidth: "6px" }}
          />
          <Cell
            key="cell-1"
            fill={COLORS_CHART[1]}
            style={{ stroke: "#f0f5f7", strokeWidth: "6px" }}
          />
        </Pie>
        <Tooltip content={customTooltip} />
      </PieChart>
      <BarChart
        data={barData}
        width={140}
        height={120}
        margin={{ top: 0, right: 50, bottom: 0, left: 50 }}
        barSize={12}
      >
        <Bar dataKey="value" animationBegin={0} animationDuration={1000} minPointSize={2}>
          <Cell key={`cell-0`} fill={COLORS_BAR[0]} />
          <Cell key={`cell-0`} fill={COLORS_BAR[1]} />
          <LabelList dataKey="value" content={renderCustomizedBarLabel} />
        </Bar>
        <Tooltip cursor={{ fill: "transparent" }} content={customTooltip} />
      </BarChart>
    </div>
  );
};

CandidatesStatsPieChart.propTypes = {
  containerClassName: PropTypes.string,
};

export default memo(CandidatesStatsPieChart);
