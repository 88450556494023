import { useMutation, useQuery, useQueryClient } from "react-query";
import queryStore from ".";
import CandidateService from "./../services/Candidate";
import config from "./../config";
import { store } from "../store";
import { setNewMatchesAvailable } from "../store/slices/Candidate";
import { STATS_QUERY_KEY } from "./Stats";
import { useLocation } from "react-router-dom";

export const NEW_MATCHES_QUERY_KEY = "newMatches";

queryStore.setQueryDefaults(NEW_MATCHES_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 0,
});

export const useGetNewMatchCandidatesQuery = (queryOptions) =>
  useQuery(
    NEW_MATCHES_QUERY_KEY,
    async () => {
      const { new_matched_candidates } = await CandidateService.getNewMatchCandidates();
      store.dispatch(setNewMatchesAvailable(true));
      return new_matched_candidates;
    },
    queryOptions
  );

export const useVoteUpCandidateQuery = () => {
  const queryClient = useQueryClient();
  const location = useLocation();

  return useMutation(
    ({ candidate_profile_status_id }) =>
      CandidateService.updateCandidateProfileStatus(
        candidate_profile_status_id,
        config.candidate.profileStatus.VOTED_UP
      ),
    {
      onMutate: async (candidate) => {
        await queryClient.cancelQueries(NEW_MATCHES_QUERY_KEY);

        var oldMatches;

        queryClient.setQueryData(NEW_MATCHES_QUERY_KEY, (old) => {
          oldMatches = old;
          return old.filter(
            (oldCandidate) =>
              oldCandidate?.candidate_profile_status_id !== candidate?.candidate_profile_status_id
          );
        });

        return { oldMatches };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(STATS_QUERY_KEY);
        if(location.pathname.includes("candidate-profile-cards")){
          window.location = "/";
        }
      },
      onError: (error, candidate, context) => {
        queryClient.setQueryData(NEW_MATCHES_QUERY_KEY, context?.oldMatches);
      },
    }
  );
};

export const useVoteDownCandidateQuery = () => {
  const queryClient = useQueryClient();
  const location = useLocation();

  return useMutation(
    ({ candidate_profile_status_id }) =>
      CandidateService.updateCandidateProfileStatus(
        candidate_profile_status_id,
        config.candidate.profileStatus.VOTED_DOWN
      ),
    {
      onMutate: async (candidate) => {
        await queryClient.cancelQueries(NEW_MATCHES_QUERY_KEY);

        var oldMatches;

        queryClient.setQueryData(NEW_MATCHES_QUERY_KEY, (old) => {
          oldMatches = old;
          return old.filter(
            (oldCandidate) =>
              oldCandidate?.candidate_profile_status_id !== candidate?.candidate_profile_status_id
          );
        });

        return { oldMatches };
      },
      onSuccess: () => {
        queryClient.invalidateQueries(STATS_QUERY_KEY);
        if(location.pathname.includes("candidate-profile-cards")){
          window.location = "/";
        }
      },
      onError: (error, candidate, context) => {
        queryClient.setQueryData(NEW_MATCHES_QUERY_KEY, context?.oldMatches);
      },
    }
  );
};
