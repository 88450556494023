export const clearWhiteSpacesAndConvertToLowerCase = (text = "") =>
  !!text ? text.replace(/\s/g, "").toLowerCase() : "";

export const isFile = (input) => "File" in window && input instanceof File;

export const HOME_TABS = {
  campaings: 0,
  interviews: 1,
  candidates: 2,
  stats: 3,
};
