import { call, put, select, takeLatest } from "@redux-saga/core/effects";
import { logError } from "../../../utils/logging";
import { HOME_TABS } from "../../../utils/misc";
import { selectSelectedCampaign } from "../../selectors/Campaign";
import { setActiveMobileTab } from "../../slices/App";
import { setCampaignRequestedModalVisibility, setSelectedCampaign } from "../../slices/Campaign";
import { requestCampaign, selectCampaignMobile } from "../actions/Campaign";
import CampaignService from "./../../../services/Campaign";

export function* requestCampaignSaga() {
  try {
    yield call(CampaignService.requestCampaign);
    yield put(setCampaignRequestedModalVisibility(true));
  } catch (e) {
    logError(e);
  }
}

export function* selectCampaignMobileSaga({ payload: campaign }) {
  try {
    const selectedCampaign = yield select(selectSelectedCampaign);

    yield put(setSelectedCampaign(campaign));

    if (selectedCampaign?.campaign_id !== campaign?.campaign_id) {
      yield put(setActiveMobileTab(HOME_TABS.candidates));
    }
  } catch (e) {
    logError(e);
  }
}

export const campaignSagas = [
  takeLatest(requestCampaign, requestCampaignSaga),
  takeLatest(selectCampaignMobile, selectCampaignMobileSaga),
];
