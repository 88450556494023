import { combineReducers } from "redux";
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import AppSlice from "./slices/App";
import AuthSlice, { logoutSuccess } from "./slices/Auth";
import CampaignSlice from "./slices/Campaign";
import CandidateSlice from "./slices/Candidate";
import CandidateFilterSlice from "./slices/CandidateFilter";
import rootSaga from "./saga";

const combinedReducers = combineReducers({
  AppSlice,
  AuthSlice,
  CampaignSlice,
  CandidateSlice,
  CandidateFilterSlice,
});

const rootReducer = (state, action) => {
  if (action.type === logoutSuccess.type) {
    state = undefined;
  }

  return combinedReducers(state, action);
};

const sagaMiddleware = createSagaMiddleware();
const middlewares = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware];

const store = configureStore({
  reducer: rootReducer,
  middleware: middlewares,
});

sagaMiddleware.run(rootSaga);

export { store };
