import request from "../utils/request";

const AUTH_API_URL = "user/api";

const AuthService = {
  login: (data) =>
    request({
      url: `${AUTH_API_URL}/userLogin`,
      method: "post",
      data,
    }),
  verifyOTP: (data) =>
    request({
      url: `${AUTH_API_URL}/verifyOTP`,
      method: "post",
      data,
    }),
  resendOTP: (data) =>
    request({
      url: `${AUTH_API_URL}/sendOTP`,
      method: "post",
      data,
    }),
  register: (data) =>
    request({
      url: `${AUTH_API_URL}/userRegister`,
      method: "post",
      data,
    }),
  userSurvey: (data, token) =>
    request({
      url: `${AUTH_API_URL}/userSurvey`,
      method: "post",
      data,
      headers: { Authorization: `Bearer ${token}` },
    }),
  setUpUserPassword: (data, token) =>
    request({
      url: `${AUTH_API_URL}/setUpUserPassword`,
      method: "post",
      data,
      headers: { Authorization: `Bearer ${token}` },
    }),
  logout: () =>
    request({
      url: `${AUTH_API_URL}/userLogout`,
      method: "post",
    }),
  sendResetPasswordOTP: (data) =>
    request({
      url: `${AUTH_API_URL}/sendResetPasswordToken`,
      method: "post",
      data,
    }),
  verifyResetPasswordOTP: (data) =>
    request({
      url: `${AUTH_API_URL}/verifyResetPasswordToken`,
      method: "post",
      data,
    }),
  createNewPassword: (data) =>
    request({
      url: `${AUTH_API_URL}/resetUserPassword`,
      method: "post",
      data,
    }),
};

export default AuthService;
