import "./NewMatchesListMobile.css";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";

import NewMatchesCard from "../../NewMatchesCard/NewMatchesCard";
import { setNewMatchesAvailable, setShowNewMatches } from "../../../../store/slices/Candidate";
import {
  useGetNewMatchCandidatesQuery,
  useVoteDownCandidateQuery,
  useVoteUpCandidateQuery,
} from "./../../../../queries/NewMatches";

export const NewMatchesListMobile = () => {
  const { data: candidates } = useGetNewMatchCandidatesQuery();
  const { mutate: voteUp } = useVoteUpCandidateQuery();
  const { mutate: voteDown } = useVoteDownCandidateQuery();
  const [index, setIndex] = useState(0);
  const [exitX, setExitX] = useState("100%");
  const [exitY, setExitY] = useState(0);
  const dispatch = useDispatch();

  const onBack = useCallback(() => dispatch(setShowNewMatches(false)), [dispatch]);

  useEffect(() => {
    if (candidates?.length === 0) {
      setTimeout(() => {
        onBack();
        dispatch(setNewMatchesAvailable(false));
      }, 400);
    }
  }, [candidates, dispatch, onBack]);

  return (
    <div className="new-matches-list-mobile">
      <div className="back-button state-transition pointer no-tap-highlight" onClick={onBack} />
      <div className="angle-background">
        <motion.svg
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3, type: "just" }}
          preserveAspectRatio="none"
          viewBox="0 0 100 100"
        >
          <polygon fill="#133b58" points="100,100 100,60 0,40 0,100" opacity="1"></polygon>
        </motion.svg>
      </div>
      <div className="cards">
        <div className="cards-container">
          <AnimatePresence initial={false}>
            {candidates?.length >= 6 && (
              <NewMatchesCard
                key={index + 5}
                initial={{ scale: 0, y: 200 }}
                animate={{ scale: 0.9, y: 40 }}
                transition={{
                  y: { duration: 0.5 },
                  scale: { duration: 0.5 },
                }}
                data={candidates[5]}
              />
            )}
            {candidates?.length >= 5 && (
              <NewMatchesCard
                key={index + 4}
                initial={{ scale: 0, y: 200 }}
                animate={{ scale: 0.9, y: 40 }}
                transition={{
                  y: { duration: 0.5 },
                  scale: { duration: 0.5 },
                }}
                data={candidates[4]}
              />
            )}
            {candidates?.length >= 4 && (
              <NewMatchesCard
                key={index + 3}
                initial={{ scale: 0, y: 200 }}
                animate={{ scale: 0.9, y: 40 }}
                transition={{
                  y: { duration: 0.5 },
                  scale: { duration: 0.5 },
                }}
                data={candidates[3]}
              />
            )}
            {candidates?.length >= 3 && (
              <NewMatchesCard
                key={index + 2}
                initial={{ scale: 0, y: 200 }}
                animate={{ scale: 0.9, y: 40 }}
                transition={{
                  y: { duration: 0.5 },
                  scale: { duration: 0.5 },
                }}
                data={candidates[2]}
              />
            )}
            {candidates?.length >= 2 && (
              <NewMatchesCard
                key={index + 1}
                initial={{ scale: 0, y: 100 }}
                animate={{ scale: 0.95, y: 20 }}
                transition={{
                  y: { duration: 0.5 },
                  scale: { duration: 0.5 },
                }}
                data={candidates[1]}
              />
            )}
            {candidates?.length >= 1 && (
              <NewMatchesCard
                key={index}
                animate={{
                  scale: 1,
                  y: 0,
                  opacity: 1,
                }}
                transition={{
                  y: { duration: 0.5 },
                  opacity: { duration: 0.2 },
                }}
                exitX={exitX}
                setExitX={setExitX}
                exitY={exitY}
                setExitY={setExitY}
                index={index}
                setIndex={setIndex}
                isLast={candidates?.length === 1}
                drag={true}
                data={candidates[0]}
                onDislike={voteDown}
                onLike={voteUp}
              />
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default NewMatchesListMobile;
