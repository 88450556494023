import "./Home.css";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectNewMatchesAvailable, selectShowNewMatches } from "../../store/selectors/Candidate";
import { setCandidatePreviewCardPosition, setPreviewCandidate } from "../../store/slices/Candidate";
import {
  selectCandidateFilterFavorites,
  selectCandidateFilterProfileStatus,
} from "../../store/selectors/CandidateFilter";
import { useIfMobile } from "./../../utils/responsive";
import HomeMobileLayout from "./HomeMobileLayout";
import HomeDesktopLayout from "./HomeDesktopLayout";

const Home = () => {
  const showNewMatches = useSelector(selectShowNewMatches);
  const newMatchesAvailable = useSelector(selectNewMatchesAvailable);
  const isFilterFavorites = useSelector(selectCandidateFilterFavorites);
  const isFilterProfileStatus = useSelector(selectCandidateFilterProfileStatus);
  const isMobile = useIfMobile();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPreviewCandidate(null));
  }, [showNewMatches, isFilterFavorites, isFilterProfileStatus, isMobile, dispatch]);

  useEffect(() => {
    let timeout;

    let handleResize = () => {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        const candidateListElem = document.getElementById("candidateList");
        const homeMobileContentElem = document.getElementById("homeMobileContent");

        if (candidateListElem && !isMobile) {
          const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = candidateListElem;

          dispatch(
            setCandidatePreviewCardPosition({
              top: offsetTop + 15,
              left: offsetLeft + 20,
              width: offsetWidth - 40,
              maxHeight: offsetHeight - 30,
            })
          );
        } else if (homeMobileContentElem && isMobile) {
          const { offsetLeft, offsetTop, offsetWidth, offsetHeight } = homeMobileContentElem;

          dispatch(
            setCandidatePreviewCardPosition({
              top: offsetTop,
              left: offsetLeft,
              width: offsetWidth,
              height: offsetHeight + 1,
            })
          );
        }
      }, 1000);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [showNewMatches, newMatchesAvailable, isMobile, dispatch]);

  return isMobile ? <HomeMobileLayout /> : <HomeDesktopLayout />;
};

export default Home;
