import { useMutation, useQuery, useQueryClient } from "react-query";
import queryStore from ".";
import CampaignService from "./../services/Campaign";

export const CAMPAIGN_QUERY_KEY = "campaign";

queryStore.setQueryDefaults(CAMPAIGN_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 0,
});

export const useGetCampaignsQuery = (queryOptions) =>
  useQuery(
    CAMPAIGN_QUERY_KEY,
    async () => {
      const { campaign } = await CampaignService.getCampaigns();
      return campaign;
    },
    queryOptions
  );

export const useToggleCampaignStatusQuery = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ campaign_id, status }) =>
      CampaignService.updateCampaignStatus(campaign_id, status === 0 ? "1" : "0"), // API requires 'string'
    {
      onMutate: async (campaign) => {
        await queryClient.cancelQueries(CAMPAIGN_QUERY_KEY);

        var oldCampaigns;

        queryClient.setQueryData(CAMPAIGN_QUERY_KEY, (old) => {
          oldCampaigns = old;
          return old.map((oldCampaign) =>
            oldCampaign?.campaign_id === campaign?.campaign_id
              ? { ...campaign, status: campaign?.status === 0 ? 1 : 0 } // API returns 'number'
              : oldCampaign
          );
        });

        return { oldCampaigns };
      },
      onError: (error, candidate, context) => {
        queryClient.setQueryData(CAMPAIGN_QUERY_KEY, context?.oldCampaigns);
      },
    }
  );
};
