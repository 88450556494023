import { toast } from "react-toastify";

export const logForDev = (e = "") => {
  if (process.env.NODE_ENV !== "production") {
    console.groupCollapsed("[devLog] Error");
    console.log({ forDev: e });
    console.trace();
    console.groupEnd();
  }
};

export const logError = (e = "") => {
  let errorMessage = e?.response?.data?.message || e?.message || e;

  if (!!errorMessage) {
    logForDev(errorMessage);
    toast.error(errorMessage, { toastId: e?.errorId });
  }
};

export const logSuccess = (message = "") => toast.success(message, { autoClose: 1500 });
