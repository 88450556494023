import "./Avatar.css";
import PropTypes from "prop-types";
import { useState } from "react";
import { motion } from "framer-motion";
import { ReactComponent as UserPhotoIcon } from "../../../assets/images/user-photo-icon.svg";

const Avatar = ({
  alt,
  src = "",
  width = 30,
  height = 30,
  backgroundColorVar = "--color-blissful-blue",
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const markAsLoaded = () => setIsLoading(false);

  const markAsError = () => setIsError(true);
  return (
    <div
      className="avatar"
      style={{
        backgroundColor: !!src ? "transparent" : `var(${backgroundColorVar})`,
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: width / 2,
      }}
    >
      {!!src ? (
        <motion.img
          src={src}
          initial={{ opacity: 0 }}
          animate={{
            opacity: isLoading && !isError ? 0 : 1,
          }}
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
          alt={alt}
          draggable={false}
          {...rest}
          onLoad={markAsLoaded}
          onError={markAsError}
        />
      ) : (
        <UserPhotoIcon width={width} height={height} onLoad={markAsLoaded} onError={markAsError} />
      )}
    </div>
  );
};

Avatar.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  width: PropTypes.number,
  height: PropTypes.number,
  backgroundColorVar: PropTypes.string,
};

export default Avatar;
