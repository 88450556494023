import "./Login.css";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion, AnimateSharedLayout } from "framer-motion";
import LoginForm from "../../../components/forms/auth/Login/LoginForm";
import { ReactComponent as Logo } from "../../../assets/images/td-logo-original.svg";
import { selectOTPEmail, selectOTPPhone } from "../../../store/selectors/Auth";
import TwoStepVerificationForm from "../../../components/forms/account/TwoStepVerification/TwoStepVerificationForm";
import { resendOTPRequest, verifyOTPRequest } from "../../../store/slices/Auth";

const animateHeadingOptions = {
  exit: { opacity: 0 },
  transition: { ease: "easeOut", duration: 0.35 },
};

const Login = () => {
  const dispatch = useDispatch();
  const otpPhone = useSelector(selectOTPPhone);
  const otpEmail = useSelector(selectOTPEmail);

  const verifyOTP = ({ code }) =>
    dispatch(verifyOTPRequest({ verification_otp: code, phone_number: otpPhone }));

  const resendOTP = () => dispatch(resendOTPRequest({ email_id: otpEmail }));

  return (
    <AnimateSharedLayout>
      <div className="w-100 d-flex flex-column flex-grow-1 justify-content-center align-items-center login-container">
        <div className="title-section text-center">
          <motion.div layout>
            <Logo className="logo w-75" />
          </motion.div>
          <AnimatePresence exitBeforeEnter>
            {otpPhone === "" && (
              <>
                <motion.h1 {...animateHeadingOptions}>Welcome Back!</motion.h1>
                <motion.h3 {...animateHeadingOptions}>Please login to proceed.</motion.h3>
              </>
            )}
          </AnimatePresence>
        </div>
        <AnimatePresence exitBeforeEnter>
          {!!otpPhone ? (
            <TwoStepVerificationForm
              key={"2fa-form"}
              onSubmit={verifyOTP}
              onResend={resendOTP}
              submitLabel="SIGN IN TO YOUR ACCOUNT"
              phone={otpPhone}
            />
          ) : (
            <LoginForm key={"login-form"} />
          )}
        </AnimatePresence>
      </div>
    </AnimateSharedLayout>
  );
};

export default Login;
