import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import ReactTooltip from "react-tooltip";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import AppRouter from "./router/AppRouter";
import { store } from "./store";
import config from "./config";
import "react-toastify/dist/ReactToastify.css";
import queryStore from "./queries";

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryStore}>
      <Provider store={store}>
        <AppRouter />
        <ReactTooltip
          delayShow={400}
          delayHide={400}
          delayUpdate={100}
          effect={"solid"}
          type={"light"}
          className={"custom__tooltip state-transition"}
        />
        <ToastContainer {...config.toast} />
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
