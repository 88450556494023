import request, { attachQueryParams } from "../utils/request";

const RECRUITER_API_URL = "recruiter/api";

const CandidateService = {
  getCandidates: (queryParams) => {
    return request({
      url: attachQueryParams(`${RECRUITER_API_URL}/getMyCandidates`, queryParams),
      method: "get",
    });
  },
  getNewMatchCandidates: () => {
    return request({
      url: `${RECRUITER_API_URL}/getNewMatchedCandidates`,
      method: "get",
    });
  },
  toggleCandidateFavoriteStatus: (candidate_profile_status_id, is_favourite) => {
    return request({
      url: `${RECRUITER_API_URL}/addCandidateToFavourite/${candidate_profile_status_id}`,
      method: "put",
      data: { is_favourite },
    });
  },
  updateCandidateProfileStatus: (candidate_profile_status_id, profile_status) => {
    return request({
      url: `${RECRUITER_API_URL}/updateCandidateProfileStatus/${candidate_profile_status_id}`,
      method: "put",
      data: { profile_status: profile_status.toString() }, // API requires status to be a string, but sends back integer when fetched
    });
  },
};

export default CandidateService;
