import { useMutation, useQuery, useQueryClient } from "react-query";
import queryStore from ".";
import { store } from "../store";
import { selectPreviewCandidate } from "../store/selectors/Candidate";
import { setPreviewCandidate } from "../store/slices/Candidate";
import CandidateService from "./../services/Candidate";

export const CANDIDATE_QUERY_KEY = "candidate";

queryStore.setQueryDefaults(CANDIDATE_QUERY_KEY, {
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
  staleTime: 0,
});

export const useGetCandidatesQuery = (queryParams, queryOptions) =>
  useQuery(
    [CANDIDATE_QUERY_KEY, queryParams],
    async () => {
      const { candidates_list } = await CandidateService.getCandidates(queryParams);
      return candidates_list;
    },
    queryOptions
  );

export const useToggleCandidateFavoriteStatusQuery = (queryParams) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ candidate_profile_status_id, is_favourite }) =>
      CandidateService.toggleCandidateFavoriteStatus(
        candidate_profile_status_id,
        is_favourite ? 0 : 1
      ),
    {
      onMutate: (candidate) => {
        if (selectPreviewCandidate(store.getState())) {
          store.dispatch(
            setPreviewCandidate({ ...candidate, is_favourite: !candidate.is_favourite })
          );
        }

        queryClient.setQueryData([CANDIDATE_QUERY_KEY, queryParams], (old) => {
          return old.map((oldCandidate) =>
            oldCandidate.candidate_profile_status_id === candidate.candidate_profile_status_id
              ? { ...oldCandidate, is_favourite: !candidate?.is_favourite }
              : oldCandidate
          );
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(CANDIDATE_QUERY_KEY);
      },
      onError: (error, candidate, context) => {
        if (selectPreviewCandidate(store.getState())) {
          store.dispatch(
            setPreviewCandidate({ ...candidate, is_favourite: candidate.is_favourite })
          );
        }

        queryClient.setQueryData([CANDIDATE_QUERY_KEY, queryParams], (old) => {
          return old.map((oldCandidate) =>
            oldCandidate.candidate_profile_status_id === candidate.candidate_profile_status_id
              ? { ...oldCandidate, is_favourite: candidate?.is_favourite }
              : oldCandidate
          );
        });
      },
    }
  );
};
