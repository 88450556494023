import "./CreateNewPasswordForm.css";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import { motion } from "framer-motion";
import Button from "../../../shared/Button/Button";
import { selectOTPPhone } from "../../../../store/selectors/Auth";
import { UnderlinedPasswordInputField } from "./../../../shared/inputs/UnderlinedInputField/UnderlinedInputField";
import { createNewPasswordRules } from "./../../../../validation/auth";
import { createNewPasswordRequest } from "../../../../store/slices/Auth";

const animateFormOptions = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { ease: "easeInOut", duration: 0.35 },
};

const CreateNewPasswordForm = () => {
  const optPhone = useSelector(selectOTPPhone);
  const dispatch = useDispatch();

  const handleFormSubmit = (values) =>
    dispatch(createNewPasswordRequest({ ...values, phone_number: optPhone }));

  return (
    <Formik
      initialValues={{
        password: "",
        confirm_password: "",
      }}
      onSubmit={handleFormSubmit}
      validationSchema={createNewPasswordRules}
    >
      {() => (
        <Form className="create-new-password-form text-center d-flex flex-column">
          <motion.div {...animateFormOptions}>
            <Field
              label="New Password"
              name="password"
              placeholder="Enter New Password"
              type="password"
              autoComplete="new-password"
              autoFocus
              component={UnderlinedPasswordInputField}
            />
            <Field
              label="Confirm Password"
              name="confirm_password"
              placeholder="Confirm New Password"
              type="password"
              autoComplete="new-password"
              component={UnderlinedPasswordInputField}
            />
            <Button
              className="w-100"
              type="submit"
              title="CONFIRM NEW PASSWORD"
              backgroundColorVar="--color-super-rare-jade"
            />
            <div className="text-start password-policy">
              <h4>Password Requirements</h4>
              <p>• Be a minimum of eight (8) characters in length.</p>
              <p>
                • Contain at least one (1) character from three (3) of the following categories:
                Uppercase letter (A-Z) Lowercase letter (a-z) Digit (0-9) Special character #(~`! …
              </p>
              <p>• Be memorized; if a password is written down it must be secure.</p>
            </div>
          </motion.div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateNewPasswordForm;
