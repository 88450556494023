import "./LoginForm.css";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Field, Form, Formik } from "formik";
import { motion } from "framer-motion";
import { loginRequest } from "../../../../store/slices/Auth";
import { loginValidationRules } from "./../../../../validation/auth";
import UnderlinedInputField from "../../../shared/inputs/UnderlinedInputField/UnderlinedInputField";
import Button from "../../../shared/Button/Button";
import { routes } from "../../../../router/routes";

const animteFormOptions = {
  exit: { opacity: 0 },
  transition: { ease: "easeOut", duration: 0.35 },
};

const LoginForm = () => {
  const dispatch = useDispatch();

  const handleFormSubmit = useCallback((values) => dispatch(loginRequest(values)), [dispatch]);

  return (
    <Formik
      initialValues={{ email_id: "", password: "", user_role: 1 }}
      validationSchema={loginValidationRules}
      onSubmit={handleFormSubmit}
    >
      {() => (
        <Form className="login-form">
          <motion.div className="text-end" {...animteFormOptions}>
            <Field
              label="Email"
              type="email"
              name="email_id"
              autoComplete="email"
              placeholder="Enter your email"
              component={UnderlinedInputField}
            />
            <Field
              label="Password"
              type="password"
              autoComplete="current-password"
              name="password"
              placeholder="Enter your password"
              component={UnderlinedInputField}
            />
            <Link to={routes.forgotPassword} className="no-tap-highlight">
              Forgot Password?
            </Link>
            <Button
              title="SIGN IN TO YOUR ACCOUNT"
              type="submit"
              backgroundColorVar="--color-super-rare-jade"
              className="w-100"
              margin="40px 0"
            />
            <p className="text-center">
              No Account?{" "}
              <Link to={routes.register} className="sign-up no-tap-highlight">
                Sign up
              </Link>
              .
            </p>
          </motion.div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
