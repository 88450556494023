import { createSelector } from "reselect";

const candidateFilterSlice = (state) => state.CandidateFilterSlice;

export const selectCandidateFilterProfileStatus = createSelector(
  candidateFilterSlice,
  (state) => state.profileStatus
);

export const selectCandidateFilterFavorites = createSelector(
  candidateFilterSlice,
  (state) => state.isFavorites
);

export const selectCandidateSearchText = createSelector(
  candidateFilterSlice,
  (state) => state.searchText
);
