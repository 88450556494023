import "./HomeMobileLayout.css";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import UpcomingInterviewList from "./../../components/interviews/UpcomingInterviewList/UpcomingInterviewList";
import { ReactComponent as CampaignsIcon } from "../../assets/images/campaign-icon.svg";
import { ReactComponent as InterviewsIcon } from "../../assets/images/interviews-icon.svg";
import { ReactComponent as CandidatesIcon } from "../../assets/images/candidate-icon.svg";
import { ReactComponent as StatsIcon } from "../../assets/images/stats-icon.svg";
import StatsMobile from "../../components/stats/StatsMobile/StatsMobile";
import CandidateListMobile from "./../../components/candidates/CandidateList/Mobile/CandidateListMobile";
import CampaignListMobile from "./../../components/campaign/CampaignList/Mobile/CampaignListMobile";
import { HOME_TABS } from "../../utils/misc";
import { selectActiveMobileTab } from "./../../store/selectors/App";
import { setActiveMobileTab } from "../../store/slices/App";
import NewMatchesListMobile from "../../components/newMatches/NewMatchesList/Mobile/NewMatchesListMobile";
import { selectShowNewMatches } from "../../store/selectors/Candidate";
import {
  activeCampaignIndicatorMobileVariants,
  tabContentMobileVariants,
} from "../../utils/animation";
import { selectSelectedCampaign } from "../../store/selectors/Campaign";

const HomeMobileLayout = () => {
  const activeTab = useSelector(selectActiveMobileTab);
  const showNewMatches = useSelector(selectShowNewMatches);
  const selectedCampaign = useSelector(selectSelectedCampaign);
  const dispatch = useDispatch();

  const setActiveTab = (tab) => dispatch(setActiveMobileTab(tab));

  const getActiveTabContent = () => {
    switch (activeTab) {
      case HOME_TABS.interviews:
        return <UpcomingInterviewList key="interviews" />;
      case HOME_TABS.candidates:
        return <CandidateListMobile key="candidates" />;
      case HOME_TABS.stats:
        return <StatsMobile key="stats" />;
      default:
        return <CampaignListMobile key="campaigns" />;
    }
  };

  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      {!showNewMatches ? (
        <motion.div
          key="home-content-mobile"
          variants={tabContentMobileVariants}
          initial={"hidden"}
          animate={"show"}
          exit={"hidden"}
          id="homeMobileContent"
          className="home-mobile d-flex flex-column"
        >
          <AnimatePresence initial={false} exitBeforeEnter>
            {getActiveTabContent()}
          </AnimatePresence>
          <div className="bottom-navigation d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-center">
              <CampaignsIcon
                className="state-transition"
                fill={activeTab === HOME_TABS.campaings ? "#14B9B8" : "#B7D2DC"}
                onClick={() => setActiveTab(HOME_TABS.campaings)}
              />
              <AnimatePresence>
                {selectedCampaign && (
                  <motion.span
                    variants={activeCampaignIndicatorMobileVariants}
                    initial={"initial"}
                    animate={"animate"}
                    exit={"exit"}
                    className="selected-campaign-indicator"
                  />
                )}
              </AnimatePresence>
            </div>
            <InterviewsIcon
              className="state-transition"
              fill={activeTab === HOME_TABS.interviews ? "#14B9B8" : "#B7D2DC"}
              onClick={() => setActiveTab(HOME_TABS.interviews)}
            />
            <CandidatesIcon
              className="state-transition"
              fill={activeTab === HOME_TABS.candidates ? "#14B9B8" : "#B7D2DC"}
              onClick={() => setActiveTab(HOME_TABS.candidates)}
            />
            <StatsIcon
              className="state-transition"
              fill={activeTab === HOME_TABS.stats ? "#14B9B8" : "#B7D2DC"}
              onClick={() => setActiveTab(HOME_TABS.stats)}
            />
          </div>
        </motion.div>
      ) : (
        <motion.div
          key="new-matches-mobile"
          variants={tabContentMobileVariants}
          initial={"hidden"}
          animate={"show"}
          exit={"hidden"}
          className="d-flex flex-column flex-grow-1"
          style={{ height: "calc(100% - 50px)" }}
        >
          <NewMatchesListMobile />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default HomeMobileLayout;
