import "./StatsSidebar.css";
import { motion } from "framer-motion";
import { Scrollbars } from "react-custom-scrollbars-2";
import StatsSectionHeader from "./../StatsSectionHeader/StatsSectionHeader";
import CandidatesStatsCardList from "./../candidates/CandidatesStatsCardList/CandidatesStatsCardList";
import CandidatesStatsPieChart from "../candidates/CandidatesStatsPieChart/CandidatesStatsPieChart";
import CampaignsStatsCardList from "./../campaigns/CampaignsStatsCardList/CampaignsStatsCardList";
import {
  ScrollThumbVertical,
  ScrollTrackVertical,
} from "./../../shared/CustomScrollBar/CustomScrollBar";
import { useGetStatsQuery } from "../../../queries/Stats";
import { ReactComponent as CandidateIcon } from "../../../assets/images/candidate-icon.svg";
import { ReactComponent as CampaignIcon } from "../../../assets/images/campaign-icon.svg";
import { sidebarContentVariants } from "../../../utils/animation";

const StatsSidebar = () => {
  const { isSuccess, data } = useGetStatsQuery();

  return (
    isSuccess && (
      <Scrollbars
        id="statsSidebar"
        className="stats-sidebar"
        hideTracksWhenNotNeeded
        renderThumbVertical={ScrollThumbVertical}
        renderTrackVertical={ScrollTrackVertical}
        renderThumbHorizontal={() => <div />}
        renderTrackHorizontal={() => <div />}
      >
        <motion.div
          variants={sidebarContentVariants}
          initial={"hidden"}
          animate={"show"}
          exit={"hidden"}
          style={{ padding: "30px 20px" }}
        >
          <StatsSectionHeader
            containerClassName="candidates-stats-header"
            icon={CandidateIcon}
            figure={data?.candidates_data?.total_matched}
            title={`Candidates\nMatched`}
          />
          <hr />
          <CandidatesStatsCardList
            containerClassName={"campaigns-stats-figures-list"}
            data={data?.candidates_data}
          />
          <CandidatesStatsPieChart
            data={data?.candidates_data}
            containerClassName={"campaigns-stats-charts"}
          />
          <hr />
          <StatsSectionHeader
            containerClassName="campaigns-stats-header"
            icon={CampaignIcon}
            figure={data?.campaigns_data?.total_campaigns}
            title={`All\nCampaigns`}
          />
          <CampaignsStatsCardList data={data?.campaigns_data} />
        </motion.div>
      </Scrollbars>
    )
  );
};

export default StatsSidebar;
