import "./CampaignsStatsCardList.css";
import PropTypes from "prop-types";
import CampaignsStatsCardListItem from "../CampaignsStatsCardListItem/CampaignsStatsCardListItem";

const CampaignsStatsCardList = ({ containerClassName, data = undefined }) => {
  return (
    <div
      className={
        "campaigns-stats__card-list" + (!!containerClassName ? ` ${containerClassName}` : "")
      }
    >
      {data && (
        <>
          <CampaignsStatsCardListItem
            key={0}
            figure={data?.total_active}
            backgroundColorVar="--color-super-rare-jade"
            title="Active"
          />
          <CampaignsStatsCardListItem
            key={1}
            figure={data?.total_new}
            backgroundColorVar="--color-brig"
            title="New"
          />
          <CampaignsStatsCardListItem
            key={2}
            figure={data?.total_archived}
            backgroundColorVar="--color-batch-blue"
            title="Paused"
          />
        </>
      )}
    </div>
  );
};

CampaignsStatsCardList.propTypes = {
  containerClassName: PropTypes.string,
  data: PropTypes.object,
};

export default CampaignsStatsCardList;
