import "./CandidatesStatsCardList.css";
import PropTypes from "prop-types";
import CandidatesStatsCardListItem from "../CandidatesStatsCardListItem/CandidatesStatsCardListItem";

const CandidatesStatsCardList = ({ containerClassName, data = undefined }) => {
  return (
    <div
      className={
        "candidates-stats__card-list" + (!!containerClassName ? ` ${containerClassName}` : "")
      }
    >
      {data && (
        <>
          <CandidatesStatsCardListItem
            key={0}
            figure={data?.total_voted_up}
            indicatorColorVar="--color-brig"
            title="Upvoted"
          />
          <CandidatesStatsCardListItem
            key={1}
            figure={data?.total_voted_down}
            indicatorColorVar="--color-raspberry-glaze"
            title="Downvoted"
          />
          <CandidatesStatsCardListItem
            key={2}
            figure={data?.total_interviewed}
            indicatorColorVar="--color-blue-heaven"
            title="Interviewed"
          />
          <CandidatesStatsCardListItem
            key={3}
            figure={data?.total_hired}
            indicatorColorVar="--color-super-rare-jade"
            title="Hired"
          />
        </>
      )}
    </div>
  );
};

CandidatesStatsCardList.propTypes = {
  containerClassName: PropTypes.string,
  data: PropTypes.object,
};

export default CandidatesStatsCardList;
