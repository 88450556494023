import "./SearchFilterBarMobile.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search-icon.svg";
import { ReactComponent as BriefCaseIcon } from "../../../../assets/images/briefcase-icon.svg";
import { ReactComponent as LikeIcon } from "../../../../assets/images/like-icon.svg";
import { ReactComponent as CheckCircleIcon } from "../../../../assets/images/check-circle-icon.svg";
import { ReactComponent as HeartIcon } from "../../../../assets/images/heart-icon.svg";
import { ReactComponent as ClearIcon } from "../../../../assets/images/x-icon.svg";
import {
  setCandidateFilterProfileStatus,
  setCandidateSearchText,
  toggleCandidateFilterFavorites,
} from "../../../../store/slices/CandidateFilter";
import {
  selectCandidateFilterFavorites,
  selectCandidateFilterProfileStatus,
  selectCandidateSearchText,
} from "../../../../store/selectors/CandidateFilter";
import config from "./../../../../config";
import { filtersMobileVariants } from "../../../../utils/animation";

const animation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.3 },
};

const SearchFilterBarMobile = () => {
  const searchText = useSelector(selectCandidateSearchText);
  const [isSearchActive, setIsSearchActive] = useState(!!searchText);
  const activeProfileStatus = useSelector(selectCandidateFilterProfileStatus);
  const isFavorites = useSelector(selectCandidateFilterFavorites);
  const dispatch = useDispatch();

  const setProfileStatus = (status) => dispatch(setCandidateFilterProfileStatus(status));
  const toggleFavorites = () => dispatch(toggleCandidateFilterFavorites());
  const setSearchText = (e) => dispatch(setCandidateSearchText(e?.target ? e?.target?.value : ""));

  return (
    <div
      className="search-filter-bar-mobile no-tap-highlight state-transition d-flex justify-content-between align-items-center flex-shrink-0"
      style={{
        backgroundColor: isSearchActive ? "#ffffff" : "#ebf5f9",
      }}
    >
      <div className="d-flex flex-grow-1 justify-content-between align-items-center">
        <div className="d-flex">
          <SearchIcon
            onClick={() => {
              setIsSearchActive(true);
            }}
          />
        </div>
        <AnimatePresence exitBeforeEnter>
          {isSearchActive ? (
            <motion.div
              key="search"
              {...animation}
              className="d-flex flex-grow-1 align-items-center"
            >
              <input
                type="text"
                autoFocus
                value={searchText}
                onChange={setSearchText}
                className="search-field flex-grow-1"
              />
              <div className="clear-icon d-flex justify-content-center align-items-center">
                <ClearIcon
                  style={{}}
                  width={12}
                  height={12}
                  onClick={(e) => {
                    setIsSearchActive(false);
                    setSearchText(null);
                  }}
                />
              </div>
            </motion.div>
          ) : (
            <motion.div
              key="filters"
              {...animation}
              className="search-filter-bar-mobile__filters d-flex flex-grow-1 align-items-center justify-content-end"
            >
              <motion.div
                animate={
                  activeProfileStatus === config.candidate.profileStatus.HIRED
                    ? "selected"
                    : "normal"
                }
                variants={filtersMobileVariants}
                className={`d-flex filter-icon${
                  activeProfileStatus === config.candidate.profileStatus.HIRED ? " selected" : ""
                }`}
              >
                <BriefCaseIcon
                  className="state-transition"
                  onClick={() => setProfileStatus(config.candidate.profileStatus.HIRED)}
                />
              </motion.div>
              <motion.div
                animate={
                  activeProfileStatus === config.candidate.profileStatus.VOTED_UP
                    ? "selected"
                    : "normal"
                }
                variants={filtersMobileVariants}
                className={`d-flex filter-icon${
                  activeProfileStatus === config.candidate.profileStatus.VOTED_UP ? " selected" : ""
                }`}
              >
                <LikeIcon
                  className="state-transition"
                  onClick={() => setProfileStatus(config.candidate.profileStatus.VOTED_UP)}
                />
              </motion.div>
              <motion.div
                animate={
                  activeProfileStatus === config.candidate.profileStatus.INTERVIEWED
                    ? "selected"
                    : "normal"
                }
                variants={filtersMobileVariants}
                className={`d-flex filter-icon${
                  activeProfileStatus === config.candidate.profileStatus.INTERVIEWED
                    ? " selected"
                    : ""
                }`}
              >
                <CheckCircleIcon
                  className="state-transition"
                  onClick={() => setProfileStatus(config.candidate.profileStatus.INTERVIEWED)}
                />
              </motion.div>
              <span className="vertical-spacer" />
              <motion.div
                animate={isFavorites ? "selected" : "normal"}
                variants={filtersMobileVariants}
                className={`d-flex filter-icon${isFavorites ? " selected" : ""}`}
              >
                <HeartIcon className="state-transition" onClick={toggleFavorites} />
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default SearchFilterBarMobile;
