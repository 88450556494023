import "./TwoStepVerificationForm.css";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import { motion } from "framer-motion";
import { twoStepVerificationValidationRules } from "../../../../validation/account";
import UnderlinedInputField from "../../../shared/inputs/UnderlinedInputField/UnderlinedInputField";
import { selectIsOTPResendPending } from "../../../../store/selectors/Auth";
import { regex } from "../../../../utils/regex";
import { useIfMobile } from "../../../../utils/responsive";
import Button from "./../../../shared/Button/Button";

const animateFormOptions = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { ease: "easeInOut", duration: 0.35 },
};

const TwoStepVerificationForm = ({
  onSubmit,
  onResend,
  submitLabel = "SUBMIT",
  submitBackgroundColorVar = "--color-super-rare-jade",
  phone,
}) => {
  const isOTPResendPending = useSelector(selectIsOTPResendPending);
  const isMobile = useIfMobile();

  const maskedPhoneNumber = phone.replace(regex.phoneMask, "*");

  return (
    <Formik
      enableReinitialize
      initialValues={{
        code: "",
      }}
      onSubmit={onSubmit}
      validationSchema={twoStepVerificationValidationRules}
    >
      {() => (
        <Form className="two-step-verification-form text-center d-flex flex-column">
          <motion.div {...animateFormOptions}>
            <h2>2 - Step Verification</h2>
            <span className="subtitle">
              A text message with your code has been sent to:
              <br />
              {maskedPhoneNumber}
            </span>
            <Field
              label="Code"
              type="text"
              name="code"
              placeholder="Enter your code"
              autoFocus={!isMobile}
              autoComplete="one-time-code"
              inputMode="numeric"
              component={UnderlinedInputField}
            />
            <Button
              className="w-100"
              type="submit"
              title={submitLabel}
              backgroundColorVar={submitBackgroundColorVar}
              margin="0 0 30px 0"
            />
            <span className="send-again">
              SMS didn't come?{" "}
              <span
                className={`${isOTPResendPending ? "" : "clickable"}`}
                onClick={isOTPResendPending ? () => {} : onResend}
              >
                Send again.
              </span>
            </span>
          </motion.div>
        </Form>
      )}
    </Formik>
  );
};

TwoStepVerificationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  phone: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  submitLabel: PropTypes.string,
  submitBackgroundColorVar: PropTypes.string,
};

export default TwoStepVerificationForm;
