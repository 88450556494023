import "./DashboardRouter.css";
import { Route, Switch } from "react-router-dom";
import { routes } from "../routes";
import Navigation from "./../../components/shared/Navigation/Navigation";
import Home from "./../../containers/Home/Home";
import Account from "./../../containers/Account/Account";
import NotFound from "./../../containers/NotFound/NotFound";
import CampaignRequestedModal from "./../../components/campaign/CampaignRequestedModal/CampaignRequestedModal";
import PrivateRoute from "../PrivateRoute";
import AIModal from "../../components/shared/AIModal/AIModal";
import NewMatchData from "../../components/newMatches/NewMatchData";
import CandidateDetails from "../../containers/Home/CandidateDetails";

const DashboardRouter = () => (
  <>
    <Navigation />
    <Switch>
      <PrivateRoute exact path={routes.home} component={Home} />
      <PrivateRoute exact path={routes.account} component={Account} />
      <PrivateRoute exact path={routes.candidateProfileCards} component={NewMatchData} />
      <PrivateRoute exact path={routes.details} component={CandidateDetails} />
      <Route path="*" component={NotFound} />
    </Switch>
    <AIModal />
    <CampaignRequestedModal />
  </>
);

export default DashboardRouter;
