import { put, select, takeLatest } from "@redux-saga/core/effects";
import { logError } from "../../../utils/logging";
import { selectIsAIActive } from "../../selectors/App";
import { setIsAIModalVisible, toggleIsAIActive } from "../../slices/App";

export function* toggleAISaga() {
  try {
    const isAIActive = yield select(selectIsAIActive);

    if (!isAIActive) {
      yield put(setIsAIModalVisible(true));
    }
  } catch (e) {
    logError(e);
  }
}

export const appSagas = [takeLatest(toggleIsAIActive, toggleAISaga)];
